const FloatingBox = () => (
  <img
    alt="Your Product"
    src="/images/box.png"
    width="140px"
    height="140px"
    className="animate-float"
  />
);

export default FloatingBox;
