import { DateTime } from 'luxon';

export function formatLocalDateTime(
  utcString: string,
  zone?: string,
  locale?: string
): { string: string; date: DateTime } {
  const effectiveUtcString = utcString.endsWith('Z')
    ? utcString
    : `${utcString}Z`;
  const utcDate = DateTime.fromISO(effectiveUtcString);
  let options = Intl.DateTimeFormat().resolvedOptions();
  const effectiveZone = zone || options.timeZone;
  const effectiveLocale = locale || options.locale;
  return {
    string: utcDate
      .setZone(effectiveZone)
      .setLocale(effectiveLocale)
      .toLocaleString(DateTime.DATETIME_SHORT),
    date: utcDate.setZone(effectiveZone).setLocale(effectiveLocale)
  };
}
