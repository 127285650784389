import { useContext, useEffect, useRef, useState } from 'react';

import { Breadcrumb, Button, Text } from '@cavela/ui';

import { ShaderGradient } from '..';
import i18next from 'i18next';
import { API_URL } from 'src/settings';
import { fetchWithAuth } from 'src/utils/fetchWithAuth';

import { Textarea } from '@mantine/core';
import { useForm } from '@mantine/form';

import ProductContext from '../../context/Product';
import useQuestions from '../../hooks/useQuestions';
import { removeNotRepliedQuestions } from '../../utils/removeNotRepliedQuestions';

const url = `${API_URL}/api/rfqs`;
const TRANSITION_DURATION = 500;

const { t } = i18next;

interface Question {
  question: string;
  answer: string | null;
  suggested_answer: string | null;
  placeholder: string | null;
  comment: string | null;
  attribute: string | null;
  correct: boolean | null;
}

const StepProductLLMQuestions = ({ onNext }: { onNext: () => void }) => {
  const { productDetail, refetch } = useContext(ProductContext);
  const { sendMultipleAnswers, error, loading } = useQuestions();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [isFormLoaded, setIsFormLoaded] = useState(false);
  const [description, setDescription] = useState('');
  const [productImage, setProductImage] = useState('');
  const [productParts, setProductParts] = useState([]);
  const [onboardingLLMQuestions, setOnboardingLLMQuestions] = useState<
    Question[]
  >([]);

  const questionRef = useRef(null);

  useEffect(() => {
    const fetchRFQ = async () => {
      const response = await fetchWithAuth(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          description
        })
      });

      if (response?.ok) {
        const { rfq } = await response.json();

        if (rfq) {
          if (!onboardingLLMQuestions?.length) {
            setOnboardingLLMQuestions(
              rfq.questions.map((question: Question) => ({
                ...question,

                suggestedAnswer: question.suggested_answer
              }))
            );

            setProductParts(rfq.document.key_components);
            setProductImage(rfq.image);
          }
        }
      }
    };

    fetchRFQ();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [description]);

  useEffect(() => {
    if (
      productDetail?.productName &&
      productDetail.productName !== description
    ) {
      setDescription(productDetail.productName);
    }
  }, [productDetail, description]);

  useEffect(() => {
    if (!isFormLoaded && !loading) {
      const timeoutId = setTimeout(() => {
        setIsFormLoaded(true);
      }, TRANSITION_DURATION * 4);

      return () => clearTimeout(timeoutId);
    }
  }, [isFormLoaded, loading]);

  const initialFormProps = () => {
    const initialValues: { [key: string]: string } = {};

    onboardingLLMQuestions.forEach((question: Question, index: number) => {
      initialValues[`${index}`] = Array.isArray(question.answer)
        ? question.answer.join(', ')
        : question.answer || '';
    });

    return { initialValues };
  };

  const onSubmit = async () => {
    if (!mantineForm.isDirty()) {
      return onNext();
    }

    await sendMultipleAnswers({
      productId: productDetail?.productId || '',
      stageId: productDetail?.stages[0].id || '',
      answers: removeNotRepliedQuestions(mantineForm.values)
    });

    if (!error) {
      refetch();

      return onNext();
    }
  };

  const onClickBack = () => {
    const questionIndex = currentQuestionIndex - 1;

    if (questionIndex < 0) return;

    setCurrentQuestionIndex(questionIndex);
  };

  const onClickNext = () => {
    const value = mantineForm.getValues()[currentQuestionIndex];
    const isLastQuestion =
      currentQuestionIndex === onboardingLLMQuestions.length - 1;
    const isFormValid = mantineForm.isValid();
    const isInputValid = isLastQuestion ? isFormValid : Boolean(value);
    const questionIndex = currentQuestionIndex + 1;

    if (isLastQuestion && isFormValid) {
      return onSubmit();
    }

    if (isInputValid) {
      setCurrentQuestionIndex(questionIndex);
    }
  };

  const formProps = initialFormProps();

  const mantineForm = useForm({
    initialValues: formProps.initialValues
  });

  const isLastQuestion =
    currentQuestionIndex === onboardingLLMQuestions.length - 1;
  const isFormValid = mantineForm.isValid();

  const isInputValid = isLastQuestion
    ? isFormValid
    : Boolean(mantineForm.getValues()[currentQuestionIndex]);

  const currentValue = mantineForm.getInputProps(`${currentQuestionIndex}`);
  const onboardingQuestions = productDetail?.stages[0].questions || [];
  const heading = t('createRFQ');

  const Loader = () => (
    <div className="fixed left-0 right-0 top-0 bottom-0 z-50 bg-white flex flex-col items-center justify-center">
      <div className="bg-white rounded-lg shadow-lg border-1 border-solid border-[var(--mantine-color-cavela-shadow-2)] flex flex-col items-center justify-center p-4">
        <div
          className="animate-spin flex flex-col items-center justify-center rounded-full bg-transparent w-5 h-5"
          style={{
            background:
              'linear-gradient(to bottom right, var(--mantine-color-cavela-primary-5) 50%, var(--mantine-color-cavela-primary-2) 51%)'
          }}
        >
          <div className="rounded-full bg-white w-[75%] h-[75%]" />
        </div>
      </div>
    </div>
  );

  return onboardingLLMQuestions?.length > 0 ? (
    <div className="flex flex-col md:flex-rowitems-center space-between gap-2">
      <div className="bg-white w-full h-full md:w-auto md:h-auto md:rounded-none left-0 right-0 fixed z-50 right-[50%] left-0 top-0 bottom-0 px-4">
        <div className="absolute left-0 right-0 top-0 h-64 p-4 bg-gradient-to-b from-white from-40% z-50">
          <Breadcrumb />
          <div className="my-4">
            <Text heading>{heading}</Text>
          </div>
        </div>
        <div className="relative flex flex-col items-start justify-start w-[75%] h-full mx-auto">
          <form
            onSubmit={(event) => event.preventDefault()}
            className="absolute w-full top-[40%] md:top-[50%] transition duration-500 ease-in-out"
          >
            {onboardingLLMQuestions.map((question: any, index: number) => {
              //
              return (
                index === currentQuestionIndex && (
                  <div ref={questionRef} key={index}>
                    <div>
                      <Text control style={{ fontSize: '1.5em' }}>
                        {question?.question}
                      </Text>
                      <div className="w-full my-4">
                        <Textarea
                          autosize
                          autoFocus
                          data-autofocus
                          flex={1}
                          size="lg"
                          w="100%"
                          aria-multiline={true}
                          value={currentValue.value}
                          minRows={1}
                          {...currentValue}
                        />
                      </div>
                    </div>
                    <div className="flex">
                      <Button
                        variant="outline"
                        onClick={
                          currentQuestionIndex === 0
                            ? () => (window.location.href = '/new-product')
                            : onClickBack
                        }
                      >
                        {t('back')}
                      </Button>
                      <Button
                        appearance={!isInputValid ? 'disabled' : 'primary'}
                        disabled={!isInputValid}
                        onClick={onClickNext}
                      >
                        {t('next')}
                      </Button>
                    </div>
                  </div>
                )
              );
            })}
          </form>
        </div>
      </div>
      <div className="hidden md:block bg-black w-auto h-auto rounded-none left-0 right-0 fixed left-[50%] right-0 top-0 bottom-0">
        <div className="absolute w-full h-full z-50 text-center flex flex-col items-center justify-center gap-4 p-6 overflow-y-auto">
          {productImage && (
            <img
              alt="Your Product"
              src={productImage}
              width="140px"
              height="140px"
              className="rounded-2xl"
            />
          )}
          <Text heading style={{ color: 'white' }}>
            {description}
          </Text>
          <div className="bg-white rounded-lg p-4 w-full text-left">
            <Text control style={{ fontSize: '1.5em' }}>
              Quantity
            </Text>
            <div className="flex flex-col gap-2 py-2">
              <div className="flex gap-2">
                <Text
                  small
                  color="var(--mantine-color-cavela-shadow-5)"
                  style={{
                    textTransform: 'capitalize',
                    minWidth: '8rem'
                  }}
                >
                  Units
                </Text>
                <Text small>{onboardingQuestions[0].answer || '-'}</Text>
              </div>
            </div>
          </div>
          <div className="bg-white rounded-lg p-4 w-full text-left">
            <Text control style={{ fontSize: '1.5em' }}>
              Aesthetics
            </Text>
            <div className="flex flex-col gap-2 py-2">
              {productParts.map((part) => (
                <Text
                  small
                  color="var(--mantine-color-cavela-shadow-5)"
                  style={{
                    textTransform: 'capitalize',
                    borderBottom:
                      '1px solid var(--mantine-color-cavela-shadow-3)',
                    paddingBottom: '.5rem'
                  }}
                >
                  {part}
                </Text>
              ))}
            </div>
          </div>
        </div>
        <ShaderGradient />
      </div>
    </div>
  ) : (
    <Loader />
  );
};

export default StepProductLLMQuestions;
