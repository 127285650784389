import { useContext, useState } from 'react';

import { Modal } from '..';
import i18next from 'i18next';

import {
  Box,
  Button,
  Flex,
  Image,
  Modal as MantineModal,
  Select,
  Text,
  Textarea,
  TextInput,
  Title
} from '@mantine/core';
import { useForm } from '@mantine/form';

import AuthContext from '../../context/Auth';
import GlobalModalContext from '../../context/GlobalModal';
import useProductsList from '../../hooks/useProductstList';
import useSendContact from '../../hooks/useSendContact';

const { Header } = MantineModal;
const { t } = i18next;

export const GENERIC_OPTION = 'Not related to a particular product';

function findProductId(productName: string, productsList: any[]) {
  return productsList.find((product) => product.productName === productName)
    ?.productId;
}

export default function ContactForm() {
  const { closeModal } = useContext(GlobalModalContext);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { productsList } = useProductsList();
  const { userInfo } = useContext(AuthContext);
  const { sendContact, loading } = useSendContact();
  const [error, setError] = useState<string | null>(null);

  const genericOption = t('contactFormGenericOption') || GENERIC_OPTION;

  const productsListData: string[] = productsList
    .map((product) => `${product.productName}`)
    .filter(Boolean);

  async function handleSubmit() {
    setError(null);

    if (mantineForm.values.product !== genericOption) {
      mantineForm.values.productId = findProductId(
        mantineForm.values.product,
        productsList
      );
    }
    const response = await sendContact(mantineForm.values);
    if (response.error) {
      return setError(response.error);
    }
    setShowConfirmation(true);
  }

  const mantineForm = useForm({
    initialValues: {
      email: userInfo?.email || '',
      product: genericOption,
      message: '',
      username: userInfo?.username || '',
      productId: ''
    }
  });

  return (
    <Modal
      opened={true}
      onClose={closeModal}
      title={<Header>{t('menuContactUs')}</Header>}
      centered
      data-testid="contact-form-modal"
    >
      {showConfirmation ? (
        <Flex
          p="md"
          direction="column"
          align="center"
          gap="lg"
          data-testid="contact-form-confirmation"
        >
          <Box w="60%">
            <Image src="/images/support-illustration.svg"></Image>
          </Box>
          <Flex direction="column" align="center">
            <Title order={3} c="cavela-primary.9">
              {t('contactFormConfirmationTitle')}
            </Title>
            <Text> {t('contactFormConfirmationBody')} </Text>
          </Flex>
        </Flex>
      ) : (
        <form onSubmit={mantineForm.onSubmit(handleSubmit)}>
          <Flex direction="column" gap="lg">
            {!userInfo?.email && (
              <TextInput
                {...mantineForm.getInputProps('email')}
                label="Email"
                data-testid="contact-form-email"
                required
              />
            )}

            {userInfo?.email && (
              <Select
                label="What product is it about?"
                {...mantineForm.getInputProps('product')}
                data={[genericOption, ...productsListData]}
              />
            )}

            <Textarea
              {...mantineForm.getInputProps('message')}
              label="What can we help you with?"
              data-testid="contact-form-message"
              required
            />
            <Text c="red" size="sm">
              {error}
            </Text>
            <Flex gap="sm" direction={{ base: 'column', md: 'row' }}>
              <Button
                variant="cancel"
                w="100%"
                onClick={closeModal}
                data-testid="contact-form-cancel"
              >
                {t('cancel')}
              </Button>
              <Button
                loading={loading}
                type="submit"
                w="100%"
                data-testid="contact-form-send"
              >
                {t('send')}
              </Button>
            </Flex>
          </Flex>
        </form>
      )}
    </Modal>
  );
}
