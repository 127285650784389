import React, { useEffect, useRef, useState } from 'react';

import { Modal } from '..';

import classes from './DynamicHtmlContent.module.css';

interface DynamicHtmlContentProps {
  content: string;
}

export const DynamicHtmlContent: React.FC<DynamicHtmlContentProps> = ({
  content
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [modalOpened, setModalOpened] = useState(false);
  const [currentImgSrc, setCurrentImgSrc] = useState('');

  // We could get rid of this if the images came in a separate prop, and not embedded in the html
  useEffect(() => {
    const images = containerRef.current?.querySelectorAll('img');
    if (images) {
      images.forEach((img) => {
        img.addEventListener('click', () => {
          setCurrentImgSrc(img.src);
          setModalOpened(true);
        });
      });
    }

    // Cleanup to remove event listeners when the component unmounts or rerenders
    return () => {
      if (images) {
        images.forEach((img) => {
          img.removeEventListener('click', () => {
            setCurrentImgSrc(img.src);
            setModalOpened(true);
          });
        });
      }
    };
  }, [containerRef]);
  return (
    <>
      <div
        ref={containerRef}
        className={classes.markdown}
        dangerouslySetInnerHTML={{
          __html: content
        }}
      />
      <Modal
        opened={modalOpened}
        onClose={() => setModalOpened(false)}
        size="xl"
      >
        <img
          src={currentImgSrc}
          alt="Large detail"
          style={{
            height: 'auto',
            width: 'auto',
            maxHeight: '100%',
            maxWidth: '100%'
          }}
        />
      </Modal>
    </>
  );
};
