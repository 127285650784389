import { useContext } from 'react';
import { Link } from 'react-router-dom';

import { t } from 'i18next';

import { Flex, Text } from '@mantine/core';

import AuthContext from '../../context/Auth';
import { NAVBAR_HEIGHT } from '../Layout/Layout';

export default function Impersonation() {
  const { userInfo } = useContext(AuthContext);
  if (!userInfo?.impersonating) {
    return null;
  }

  return (
    <Flex
      mah={NAVBAR_HEIGHT}
      bg="cavela-primary.2"
      gap="sm"
      p={5}
      c="cavela-primary.9"
      styles={{
        root: {
          position: 'fixed',
          overflow: 'hidden',
          top: 0,
          left: 0,
          right: 0,
          zIndex: 9999
        }
      }}
    >
      <Text size="sm" lineClamp={1}>
        {userInfo.username} ({userInfo.id})
      </Text>
      <Text size="sm" lineClamp={1}>
        - by: {userInfo.impersonating.username} ({userInfo.impersonating.id})
      </Text>
      <Text size="sm" lineClamp={1}>
        <Link
          to="?impersonation=0"
          title="Stop impersonating"
          reloadDocument={true}
        >
          {t('exit')}
        </Link>
      </Text>
    </Flex>
  );
}
