import { createContext } from 'react';

import { AuthContextValue } from '../providers/AuthProvider';

const AuthContext = createContext<AuthContextValue>({
  isLoggedIn: false,
  isLoading: false,
  error: null,
  userInfo: null,
  refetch: () => {}
});

export default AuthContext;
