import { ComponentProps } from 'react';
import ReactMarkdown from 'react-markdown';

import { mdVariableSubstitution } from '../../utils/mdVariableSubstitution';
import ImageGallery from '../ImageGallery/ImageGallery';

interface MarkdownProps extends ComponentProps<typeof ReactMarkdown> {
  replace?: { [key: string]: string };
  children?: string;
  galleryProps?: {
    [key: string]: any;
  };
}

/**
 * This is a wrapper component that extends the ReactMarkdown component and extracts all the images within the markdown and attaches a library at the end of the markdown to display them. It also allows for string interpolation in the markdown strings.
 *
 * @param replace - An optional object where each key-value pair represents a variable and its replacement. I.e. In markding "{{producName}}" should be indicated as { productName: "Product Name"}
 * @param children - The markdown string to process. It can contain variables.
 * @param ...props - The props to pass to the react-markdown component as options.
 * @returns The processed markdown string with the variables replaced.
 */

const extractImageUrls = (markdown: string) => {
  const regex = /!\[.*?\]\((.*?)\)/g;
  let urls = [];
  let match;
  while ((match = regex.exec(markdown)) !== null) {
    urls.push(match[1]);
  }
  return urls;
};

const removeImages = (markdown: string) => {
  return markdown.replace(/!\[.*?\]\(.*?\)/g, '');
};

const removeFinalNewLine = (markdown: string) => {
  return markdown.replace(/\n$/, '');
};

function prepareMarkdown(
  initialMarkdown: string,
  replace: { [key: string]: string } = {}
): { markdown: string; imageUrls: string[] } {
  const imageUrls = extractImageUrls(initialMarkdown);
  let markdown = removeImages(initialMarkdown);
  markdown = mdVariableSubstitution(markdown, replace);
  markdown = removeFinalNewLine(markdown);

  return { markdown, imageUrls };
}

export default function CustomMarkdownWithGallery({
  replace,
  children,
  galleryProps,
  ...props
}: MarkdownProps) {
  if (!children) {
    return null;
  }

  const mdToRender = prepareMarkdown(children, replace);

  return (
    <>
      <ReactMarkdown components={{ img: () => null }} {...props}>
        {mdToRender.markdown}
      </ReactMarkdown>

      {mdToRender.imageUrls?.length > 0 ? (
        <ImageGallery markdownImages={mdToRender.imageUrls} {...galleryProps} />
      ) : (
        ''
      )}
    </>
  );
}
