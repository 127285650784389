export function mdVariableSubstitution(
  markdownText: string,
  variables?: { [key: string]: string }
) {
  if (!variables) {
    return markdownText;
  }
  const newText = markdownText.replace(/\{\{(\w+)\}\}/g, (match, key) => {
    return variables[key] || match;
  });

  return newText;
}
