import {
  Dispatch,
  KeyboardEventHandler,
  SetStateAction,
  useContext,
  useEffect,
  useState
} from 'react';

import { Button, Text } from '@cavela/ui';

import i18next from 'i18next';

import { TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';

import AuthContext from '../../context/Auth';
import useNewProduct from '../../hooks/useNewProduct';
import { trackOnboardingStep } from '../../services/analytics/analytics';
import {
  ONBOARDING_PRODUCT_NAME_COMPLETED,
  ONBOARDING_PRODUCT_NAME_STARTED
} from '../../services/analytics/eventCatalog';
import { getRandomSuggestions } from '../../utils/copiesGetters';

const { t } = i18next;

const MIN_NAME_LENGTH = 2;
const MAX_NAME_LENGTH = 1000;
const TRANSITION_DURATION = 500;
const CTA_BUTTON_PADDING = '2.4rem';

const validations = {
  title: (value = '') => {
    if (value.length < MIN_NAME_LENGTH) {
      return t('errorTitleTooShort');
    }

    if (value.length > MAX_NAME_LENGTH) {
      return t('errorTitleTooLong');
    }

    return null;
  }
};

const StepCreateProduct = ({
  setError
}: {
  setError?: Dispatch<SetStateAction<string>>;
}) => {
  const { search } = window.location;

  const name =
    search && decodeURIComponent(window.location.search.split('=').pop() || '');

  const [placeholderValue] = useState(
    (getRandomSuggestions('productSearches', true) as string) || ''
  );

  const [loading, setLoading] = useState(true);

  const { createProduct, newProductError } = useNewProduct();
  const { refetch: refetchMe } = useContext(AuthContext);

  useEffect(() => {
    trackOnboardingStep(ONBOARDING_PRODUCT_NAME_STARTED);
    setLoading(false);
  }, []);

  useEffect(() => {
    if (newProductError && setError) {
      setError(newProductError);
      setLoading(false);
    }
  }, [newProductError, setError]);

  const onClickNext = async () => {
    await createProduct({
      title: mantineForm.values.title
    });

    if (newProductError) return;

    setLoading(true);

    setTimeout(async () => {
      if (newProductError) return;

      refetchMe();
      trackOnboardingStep(ONBOARDING_PRODUCT_NAME_COMPLETED);
      setLoading(false);
    }, TRANSITION_DURATION * 2);
  };

  const onKeyDown: KeyboardEventHandler<HTMLInputElement> = ({
    keyCode,
    target
  }) => {
    if (keyCode === 13 && isInputValid) {
      return onClickNext();
    }

    mantineForm.setValues({
      title: (target as HTMLInputElement).value
    });
  };

  const mantineForm = useForm({
    initialValues: {
      title: name
    },
    validate: {
      title: validations.title
    }
  });

  const currentValue = mantineForm.getInputProps('title');
  const isInputValid = mantineForm.isValid();

  return (
    <div
      className="flex flex-col items-start justify-center w-[75%] h-full gap-3 mx-auto transition-opacity duration-500 ease-in-out"
      style={{ opacity: loading ? 0 : 1 }}
    >
      <Text control style={{ fontSize: '1.5em' }}>
        {t('onboardingNameStepDescription')}*
      </Text>
      <div className="w-full">
        <TextInput
          flex={1}
          size="lg"
          data-autofocus
          placeholder={placeholderValue}
          onKeyDown={onKeyDown}
          {...currentValue}
        />
      </div>
      <Button
        appearance={isInputValid ? 'primary' : 'disabled'}
        onClick={onClickNext}
        disabled={!isInputValid}
        style={{
          paddingLeft: CTA_BUTTON_PADDING,
          paddingRight: CTA_BUTTON_PADDING,
          margin: '.75rem 0 0'
        }}
      >
        {t('next')}
      </Button>
    </div>
  );
};

export default StepCreateProduct;
