import { ReactNode, useEffect, useState } from 'react';

import { Text } from '@cavela/ui';

// @ts-ignore
import FontFaceObserver from 'fontfaceobserver';

export default function Page({
  title = '',
  loading = false,
  children
}: {
  title?: string;
  loading?: boolean;
  children: ReactNode;
}) {
  const [fontsLoaded, setFontsLoaded] = useState(false);

  useEffect(() => {
    const loadFonts = async () => {
      const title = new FontFaceObserver('Cabinet Grotesk Bold');

      await title.load();

      const display = new FontFaceObserver('Cabinet Grotesk');

      await display.load();

      const body = new FontFaceObserver('Poppins');

      await body.load();

      const light = new FontFaceObserver('Poppins Light');

      await light.load();

      const bold = new FontFaceObserver('Poppins Bold');

      await bold.load();

      setFontsLoaded(true);
    };

    loadFonts();
  }, []);

  return !fontsLoaded || loading ? (
    <div className="fixed left-0 right-0 top-0 bottom-0 z-50 bg-white" />
  ) : (
    <div className="flex-1 overflow-auto">
      <div className="flex flex-col mb-8">
        {!loading && title && <Text heading>{title}</Text>}
        <div className="2xl:mx-auto w-full p-8">{children}</div>
      </div>
    </div>
  );
}
