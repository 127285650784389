import { IconCheck, IconX } from '@tabler/icons-react';
import { useContext } from 'react';

import i18next from 'i18next';
import { DateTime } from 'luxon';

import { Blockquote, Button, Flex, Text, Tooltip } from '@mantine/core';

import AuthContext from '../../context/Auth';
import { formatLocalDateTime } from '../../utils/formatLocalDateTime';

import styles from './QuestionReplied.module.css';

const { t } = i18next;

interface QuestionRepliedProps {
  answer: string | string[];
  answeredAt: string;
  questionType: string;
}

function makeTimeString(answeredAt: string | null, language: string): string {
  if (!answeredAt) {
    return '';
  }
  const answeredAtDate = answeredAt
    ? formatLocalDateTime(answeredAt).date
    : DateTime.now();

  const diff = DateTime.now().diff(answeredAtDate);
  const relativeDate = DateTime.now()
    .minus(diff)
    .toRelative({ locale: language });

  if (relativeDate.includes(t('second') || 'seconds')) {
    return t('now') || '';
  }
  return relativeDate;
}

export default function QuestionReplied({
  answer,
  answeredAt,
  questionType
}: QuestionRepliedProps) {
  const { userInfo } = useContext(AuthContext);
  const formattedDate = formatLocalDateTime(answeredAt).string;
  const relativeDate = makeTimeString(answeredAt, userInfo?.language || 'en');
  const checkAnswerContent = (answer: string | string[]): string => {
    if (Array.isArray(answer)) {
      return answer.join(', ');
    }
    return answer;
  };

  return (
    <Flex direction="column" gap="md" data-testid="question-replied-container">
      <Text size="sm">
        {t('questionsRepliedOn') || ''}
        {relativeDate && (
          <Tooltip label={formattedDate}>
            <Text size="sm" span className={styles.tooltip}>
              {' '}
              {relativeDate}
            </Text>
          </Tooltip>
        )}
        :
      </Text>

      {questionType === 'approval' ? (
        <>
          {answer[0] === 'rejected' && answer[1] && (
            <Blockquote color="cavela-primary.3">{answer[1]}</Blockquote>
          )}
          <Flex gap="sm">
            <Button
              flex={1}
              bg={answer[0] === 'rejected' ? '#F6a5a5' : 'cavela-shadow.2'}
              c="black"
              disabled
              data-testid="rejected-button"
            >
              <Flex justify="center" align="center" gap="sm">
                <IconX color={answer[0] === 'rejected' ? 'black' : 'gray'} />
                <Text>Reject</Text>
              </Flex>
            </Button>
            <Button
              flex={1}
              data-testid="approved-button"
              disabled
              bg={
                answer[0] === 'approved'
                  ? 'cavela-primary.6'
                  : 'cavela-shadow.2'
              }
              c="black"
            >
              <Flex justify="center" align="center" gap="sm">
                <IconCheck
                  color={answer[0] === 'approved' ? 'black' : 'gray'}
                />
                <Text>Approve</Text>
              </Flex>
            </Button>
          </Flex>
        </>
      ) : (
        <Blockquote color="cavela-primary.3">
          {checkAnswerContent(answer)}
        </Blockquote>
      )}
    </Flex>
  );
}
