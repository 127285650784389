import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import i18next from 'i18next';

import { captureException } from '../services/reporting';
import { API_URL } from '../settings';
import { fetchWithAuth } from '../utils/fetchWithAuth';

const { t } = i18next;

interface CreateProductProps {
  title?: string;
}

const getProductNameError = (status = 500) =>
  `${status === 400 ? t('createNewProductNameError') : t('createNewProductError')}`;

export default function useNewProduct() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [newProductError, setNewProductError] = useState<string | null>(null);
  const navigate = useNavigate();

  async function createProduct(props?: CreateProductProps) {
    setIsLoading(true);
    setNewProductError(null);
    const body = props?.title
      ? JSON.stringify({ name: props.title })
      : undefined;
    try {
      const response = await fetchWithAuth(`${API_URL}/api/products/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body
      });

      if (!response?.ok) {
        return setNewProductError(getProductNameError(response.status));
      }

      const data = await response.json();

      navigate(`/products/${data.id}`);

      return;
    } catch (error: any) {
      const { statusCode } = error || {};

      captureException({
        error,
        extra: {
          statusCode
        }
      });

      return setNewProductError(getProductNameError(statusCode));
    } finally {
      setIsLoading(false);
    }
  }

  return {
    createProduct,
    isLoading,
    newProductError
  };
}
