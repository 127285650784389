export function formatTitle(str: string): string {
  if (str && str.length > 0) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  return str;
}

export function removeHyphensAndFormat(str: string): string {
  if (str && str.length > 0) {
    const formattedStr = str.replace(/-/g, ' ');
    return formattedStr.charAt(0).toUpperCase() + formattedStr.slice(1);
  }
  return str;
}
