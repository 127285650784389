import { BadgeProps } from './props';

const useLogic = ({ variant, text }: BadgeProps) => {
  const bgColor =
    variant === 'draft'
      ? 'bg-[#E5E7EB] text-black'
      : 'bg-green-100 text-green-800';

  return { bgColor };
};

export default useLogic;
