import { CSSProperties, Dispatch, SetStateAction } from 'react';

import i18next from 'i18next';

import { Notification, Text } from '@mantine/core';

import {
  cavelaCaution as caution,
  cavelaPrimary as primary
} from '../../styles/theme';

const { t } = i18next;

interface Props {
  isSuccess?: boolean;
  isError?: boolean;
  heading: string | null;
  text: string | null;
  setSuccess?: Dispatch<SetStateAction<boolean>>;
  style?: CSSProperties;
}

const SuccessErrorNotification = ({
  isSuccess = true,
  isError = false,
  heading = t('submitSuccessHeading'),
  text = t('submitSuccess'),
  setSuccess = () => {},
  style = {}
}: Props) => {
  const color = isSuccess && !isError ? primary[7] : caution[5];

  const title = (
    <Text size="md" color={color}>
      {heading}
    </Text>
  );

  const closeNotif = t('closeNotification');

  return (
    <Notification
      color={color}
      title={title}
      closeButtonProps={{
        'aria-label': closeNotif
      }}
      onClose={() => setSuccess(false)}
      withCloseButton={false}
      px={32}
      py={16}
      style={{
        ...style,

        position: 'fixed',
        bottom: 20,
        right: 20,
        width: '20rem',
        background: 'white',
        boxShadow: `0 .5rem 2rem ${primary[1]}`,
        zIndex: 9000
      }}
    >
      <Text size="sm">{text}</Text>
    </Notification>
  );
};

export default SuccessErrorNotification;
