import { Flex } from '@mantine/core';

import { FileItemTemp } from './FileItemTemp';

export function SelectedFilesContainer({
  selectedFiles,
  onDelete,
  onAddCaption
}: {
  selectedFiles: File[];
  onDelete: (index: number) => void;
  onAddCaption?: (index: number, caption: string) => void;
}) {
  return (
    <Flex direction="column" gap="sm" data-testid="selected-files-container">
      {selectedFiles.map((file: File, index) => (
        <FileItemTemp
          onAddCaption={onAddCaption}
          file={file}
          onRemove={onDelete}
          index={index}
          key={index}
        />
      ))}
    </Flex>
  );
}
