import { useContext, useState } from 'react';

import {
  StepProductFiles,
  StepProductFinal,
  StepProductQuestions,
  StepProductRfqQuestions
} from '../components';
import ProductContext from '../context/Product';

export type OnboardingStages =
  | 'questions'
  | 'files'
  | 'rfq_questions'
  | 'final';

export interface StepProps {
  onNext: () => void;
  onPrev: () => void;
  isLast: boolean;
  isFirst: boolean;
  disabled: boolean;
}

const steps: OnboardingStages[] = ['questions', 'files', 'final'];

export default function useOnboardingFlow() {
  const { productDetail } = useContext(ProductContext);

  const hasFiles = productDetail?.stages[0].files?.length;

  const currentStepCalc = () => {
    if (!hasFiles) {
      return 0;
    }

    return 2;
  };

  const [currentStep, setCurrentStep] = useState(currentStepCalc());
  const totalSteps = steps.length;

  function nextStep() {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  }

  function prevStep() {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  }

  const stepMap = {
    questions: StepProductQuestions,
    files: StepProductFiles,
    rfq_questions: StepProductRfqQuestions,
    final: StepProductFinal
  };

  function makeOnboardingStep({ disabled = true }) {
    const stepProps: StepProps = {
      onNext: nextStep,
      onPrev: prevStep,
      isLast: currentStep === steps.length - 1,
      isFirst: currentStep === 0,
      disabled
    };

    const StepComponent = stepMap[steps[currentStep]];

    return <StepComponent {...stepProps} />;
  }

  return {
    makeOnboardingStep,
    totalSteps,
    currentStep,
    prevStep,
    nextStep
  };
}
