import { ReactNode } from 'react';

import { Page, SidebarNav } from '..';

import { Flex } from '@mantine/core';

export default function PageWrapper({
  pageTitle = '',
  loading = false,
  children
}: {
  pageTitle?: string;
  loading?: boolean;
  children: ReactNode;
}) {
  return (
    <Flex pos="absolute" left={0} right={0} top={0} bottom={0}>
      <SidebarNav />
      <Page title={pageTitle} loading={loading}>
        {children}
      </Page>
    </Flex>
  );
}
