import { useCallback, useContext, useEffect, useState } from 'react';

import { DateTime } from 'luxon';

import AuthContext from '../context/Auth';
import { captureException } from '../services/reporting';
import { API_URL } from '../settings';
import { MappedUpdate } from '../types/UpdateTypes';
import { fetchWithAuth } from '../utils/fetchWithAuth';
import { sortByLuxonDate } from '../utils/sortDates';
import updatesMapper from '../utils/updatesMappers';

export interface updatesGroupByDate {
  date: string;
  updates: MappedUpdate[];
}

export default function useUpdates({
  fetchOnLoad = false,
  productId
}: {
  fetchOnLoad?: boolean;
  productId: string;
}) {
  const { userInfo } = useContext(AuthContext);
  const [updates, setUpdatesData] = useState<updatesGroupByDate[]>([]);
  const [loading, setLoading] = useState(true);
  const [errorCode, setErrorCode] = useState(0);

  const fetchUpdates = useCallback(async () => {
    if (!userInfo) return;

    setLoading(true);

    try {
      const url = `${API_URL}/api/users/${userInfo.id}/updates?${new URLSearchParams(
        {
          product_id: productId
        }
      )}`;

      const response = await fetchWithAuth(url);

      if (response.status !== 200) {
        setErrorCode(response.status);

        return;
      }

      const data = await response.json();

      const mappedUpdates = updatesMapper(data);
      const groupedUpdates = groupUpdatesByDate(mappedUpdates);
      const sortedUpdates = sortUpdates(groupedUpdates);

      setUpdatesData(sortedUpdates);
    } catch (error: any) {
      const extra = {
        statusCode: error.statusCode
      };
      captureException({ error, extra });
    } finally {
      setLoading(false);
    }
  }, [userInfo, productId]);

  useEffect(() => {
    if (fetchOnLoad) fetchUpdates();
  }, [fetchUpdates, fetchOnLoad]);

  const groupUpdatesByDate = (
    notGroupedUpdates: MappedUpdate[]
  ): updatesGroupByDate[] => {
    const updatesByDate: { date: string; updates: MappedUpdate[] }[] = [];

    for (const update of notGroupedUpdates) {
      // Removes time from date to be able to group by date
      const date = DateTime.fromISO(update.createdAt).toISODate() || '';
      const existingEntry = updatesByDate.find((entry) => entry.date === date);
      if (existingEntry) {
        existingEntry.updates.push(update);
      } else {
        updatesByDate.push({ date, updates: [update] });
      }
    }

    return updatesByDate;
  };

  const sortUpdates = (groupedUpdates: updatesGroupByDate[]) => {
    return groupedUpdates.sort((a, b) => sortByLuxonDate(a.date, b.date));
  };

  const getLatestUpdates = (numOfUpdates: number): updatesGroupByDate[] => {
    return updates.slice(0, numOfUpdates);
  };

  return {
    updates,
    getLatestUpdates,
    loading,
    errorCode,
    fetchUpdates
  };
}
