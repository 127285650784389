import { IconFile, IconPencilQuestion, IconX } from '@tabler/icons-react';

import i18next from 'i18next';

import { ActionIcon, Box, Flex, Text, Textarea } from '@mantine/core';

import { bytesToMbs, isOverLimitedMb } from '../../utils/fileSizeHelpers';
import truncateFileName from '../../utils/truncateFileName';

import styles from '../Files/FileItem.module.css';

const { t } = i18next;

export interface FileItemTempProps {
  file: File;
  index: number;
  onRemove: (index: number) => void;
  onAddCaption?: (index: number, caption: string) => void;
}

export function FileItemTemp({
  file,
  index,
  onRemove,
  onAddCaption
}: FileItemTempProps) {
  return (
    <Flex
      data-testid="file-item-temp"
      justify="space-between"
      className={styles.fileItemWrapper}
      direction="column"
      p="xs"
    >
      <Flex gap="md" w="100%" justify="space-between">
        <Flex gap="md">
          <Box className={styles.iconWrapperUpload}>
            <IconFile color="var(--mantine-color-cavela-shadow-9)" />
          </Box>
          <Flex direction="column" flex={1}>
            <Flex
              direction="column"
              align="flex-start"
              justify="flex-start"
              gap={0}
            >
              <Text truncate fw="bold" data-testid="file-name">
                {truncateFileName(file.name, 48)}
              </Text>

              <Text
                size="sm"
                c="cavela-shadow.8"
                styles={{
                  root: {
                    whiteSpace: 'nowrap'
                  }
                }}
              >
                {`${bytesToMbs({ bytes: file.size }).toString()}MB`}

                <Text span size="xs" c="red" ml="xs" data-testid="inline-error">
                  {isOverLimitedMb({ bytes: file.size })
                    ? t('errorFileSizeInline')
                    : ''}
                </Text>
              </Text>
            </Flex>
          </Flex>
        </Flex>
        <ActionIcon.Group>
          <ActionIcon
            size="lg"
            variant="light"
            aria-label="Remove element"
            data-testid="remove-btn"
            styles={{ icon: {} }}
            onClick={() => onRemove(index)}
          >
            <IconX />
          </ActionIcon>
        </ActionIcon.Group>
      </Flex>
      {onAddCaption && (
        <Textarea
          leftSection={<IconPencilQuestion size={20} />}
          styles={{ section: { alignItems: 'flex-start', paddingTop: '4px' } }}
          p="lg"
          placeholder={t('captionForFile') || 'Add caption for file'}
          onChange={(event) => onAddCaption(index, event.currentTarget.value)}
        />
      )}
    </Flex>
  );
}
