import { IconX } from '@tabler/icons-react';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import i18next from 'i18next';

import {
  ActionIcon,
  Box,
  Button,
  Flex,
  Notification,
  Text,
  Title
} from '@mantine/core';

import { Modal } from '../..';
import ProductContext from '../../../context/Product';
import useDeleteProduct from '../../../hooks/useDeleteProduct';
import { trackOnboardingStep } from '../../../services/analytics/analytics';
import { ONBOARDING_PRODUCT_CANCELLED } from '../../../services/analytics/eventCatalog';

const { t } = i18next;

export default function CancelOnboardingButton() {
  const { productDetail } = useContext(ProductContext);
  const { deleteProduct, loading, error } = useDeleteProduct();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const navigate = useNavigate();

  async function handleCancelOnboarding() {
    trackOnboardingStep(ONBOARDING_PRODUCT_CANCELLED);
    if (productDetail?.productId === undefined) {
      return;
    }

    const response = await deleteProduct(productDetail?.productId);
    if (!response.error) {
      navigate('/');
      return;
    }
    setShowNotification(true);
  }

  return (
    <>
      {showNotification && (
        <Notification
          color="red"
          title={t('errorGenericTitle')}
          closeButtonProps={{ 'aria-label': 'Close notification' }}
          onClose={() => setShowNotification(false)}
        >
          {error}
        </Notification>
      )}
      <Modal
        opened={showConfirmation}
        centered
        onClose={() => setShowConfirmation(false)}
      >
        <Flex direction="column" gap="sm" align="center" mb="xl">
          <Title order={3} ta="center">
            {t('cancelOnboardingConfirmationTitle')}
          </Title>
          <Text ta="center">
            {t('cancelOnboardingConfirmationDescription')}
          </Text>
        </Flex>

        <Flex gap="sm" direction="column">
          <Button
            variant="outline"
            w="100%"
            onClick={() => setShowConfirmation(false)}
          >
            {t('onboardingConfirmationBack')}
          </Button>
          <Button
            onClick={handleCancelOnboarding}
            form="product-file-upload"
            w="100%"
            data-testid="confirm-cancel-cta"
          >
            {t('onboardingConfirmationCTA')}
          </Button>
        </Flex>
      </Modal>
      <Box>
        <ActionIcon
          size="xl"
          variant="light"
          data-testid="cancel-onboarding-btn"
          onClick={() => setShowConfirmation(true)}
          loading={loading}
          className="mt-4"
        >
          <IconX />
        </ActionIcon>
      </Box>
    </>
  );
}
