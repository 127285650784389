import { Dispatch, SetStateAction, useContext } from 'react';

import i18next from 'i18next';

import { Button, Flex, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';

import ProductContext from '../../context/Product';

const { t } = i18next;

export function ProductTitleForm({
  title,
  setTitle,
  onCancel
}: {
  title: string;
  setTitle: Dispatch<SetStateAction<string>>;
  onCancel: () => void;
}) {
  const { editProduct, productDetail, refetch, loading, error } =
    useContext(ProductContext);

  async function handleEdit(props: { [key: string]: string }) {
    // Checks for changes, otherwise doesn't call the API
    if (!mantineForm.isDirty()) {
      return onCancel();
    }

    if (productDetail?.productId) {
      const response = await editProduct({
        productId: productDetail.productId,
        data: props
      });

      if (!response.error) {
        refetch();
      }

      if (!loading) {
        onCancel();
      }

      setTitle(props.name);

      return { error: response.error || '' };
    }
    return { error: 'Product ID not found' };
  }

  const mantineForm = useForm({
    initialValues: {
      title
    },
    validate: {
      title: (value) => {
        if (value.length < 3) {
          return t('errorTitleTooShort');
        }
        if (value.length > 255) {
          return t('errorTitleTooLong');
        }
        return null;
      }
    }
  });

  return (
    <Flex
      gap="md"
      w={{ base: '100%' }}
      maw={{ md: '700px' }}
      direction="column"
    >
      <Flex flex={1}>
        <form
          style={{ width: '100%' }}
          id="product-title-form"
          onSubmit={mantineForm.onSubmit((values) => {
            handleEdit({ name: values.title });
          })}
        >
          <TextInput
            flex={1}
            size="xl"
            data-autofocus
            placeholder="My cool product"
            data-testid="product-title-input"
            {...mantineForm.getInputProps('title')}
            radius="md"
          />
          {error && (
            <Text size="sm" c="red" data-testid="error-placeholder">
              {error}
            </Text>
          )}
        </form>
      </Flex>
      <Flex gap="xs" justify="flex-end">
        <Button w="100" variant="cancel" onClick={() => onCancel()}>
          {t('cancel')}
        </Button>
        <Button
          w="100"
          type="submit"
          loading={loading}
          form="product-title-form"
          data-testid="save-product-title"
        >
          Save
        </Button>
      </Flex>
    </Flex>
  );
}
