import { Checkbox, Flex } from '@mantine/core';

export default function MultipleChoiceQuestion(questionProps: any) {
  const { isAnswered, sendError, sendLoading, questionType, options, ...rest } =
    questionProps;

  return (
    <Checkbox.Group
      name="multiple-choice-question"
      error={sendError}
      errorProps={sendError}
      {...rest}
    >
      <Flex direction="column" gap="lg">
        {options.map((option: string) => (
          <Checkbox
            value={option}
            key={option}
            label={option}
            disabled={sendLoading || isAnswered}
          ></Checkbox>
        ))}
      </Flex>
    </Checkbox.Group>
  );
}
