import {
  appCopies,
  menuItems,
  notAccessibleCopies,
  questionsCopies,
  randomSuggestions,
  registerLoginCopies,
  stageCopies,
  statusCopies,
  statusReasonsCopies
} from '../data/copies';
import { RoutePath } from '../routes';
import { captureException } from '../services/reporting';
import { Status } from '../types/ProductTypes';
import { removeHyphensAndFormat } from '../utils/formatters';

export interface StageStatusCopy {
  title: string;
  description: string | JSX.Element;
}

/* These functions allow to change copies origin or add new ones without the need to change the frontend references in the components, unless new are added */

/* When i18n is implemented, these functions might replaced by a i18n library, or they can be modified to deliver the correct translated text without the need of changing the components */

export const getGenericStageCopy = (stageId: string): StageStatusCopy => {
  return {
    title: removeHyphensAndFormat(stageId),
    description: ''
  };
};

export function getStatusCopies(status: Status) {
  return { title: statusCopies[status].title };
}

export function getQuestionsCopies() {
  return {
    disclaimer: questionsCopies.disclaimer
  };
}

export function getStageCopies({
  stageId,
  productName,
  numSuppliers,
  status,
  statusCode
}: {
  stageId: string;
  productName?: string;
  numSuppliers?: number;
  status: string;
  statusCode: string | null;
}): StageStatusCopy {
  let stageIdKey: keyof typeof stageCopies;
  let statusCopy: Status;
  let statusReasonCopy: string | null = null;

  if (!stageId)
    return {
      title: '',
      description: ''
    };

  // Check if the stageId is included in the copies.tsx file else use a generic copy and sent alert to check why it's not included
  if (Object.keys(stageCopies).includes(stageId)) {
    stageIdKey = stageId as keyof typeof stageCopies;
  } else {
    captureException({
      error: new Error(
        `Error retrieving the copies for stage ${stageId}. The key was not found.`
      ),
      extra: {
        hint: "Maybe there's a new stage_id not included in the copies.tsx file or the stage_id has been modified and doesn't match with the index."
      }
    });
    return getGenericStageCopy(stageId);
  }

  if (Object.keys(statusCopies).includes(status)) {
    statusCopy = status as Status;
  } else {
    captureException({
      error: new Error(
        `Error retrieving the copies for status ${status}. The key was not found.`
      ),
      extra: {
        hint: "Maybe there's a new status type not included in the copies.tsx file"
      }
    });
    statusCopy = 'pending';
  }

  if (statusCode) {
    if (Object.keys(statusReasonsCopies).includes(statusCode)) {
      statusReasonCopy = statusReasonsCopies[statusCode];
    } else {
      captureException({
        error: new Error(
          `Error retrieving the copies for the status reason code ${statusCode}. The key was not found.`
        ),
        extra: {
          hint: "Maybe there's a new status type not included in the copies.tsx file"
        }
      });
    }
  }

  let stageCopy: StageStatusCopy = {
    title: '',
    description: ''
  };

  switch (stageIdKey) {
    case 'quotes_received':
      stageCopy = stageCopies.quotes_received(productName)[statusCopy];
      break;
    case 'rfq_sent':
      stageCopy = stageCopies.rfq_sent(productName, numSuppliers || 0)[
        statusCopy
      ];
      break;
    default:
      stageCopy = stageCopies[stageIdKey][statusCopy];
  }

  // If there's a statusReasonCode, overwrites the generic with the statusReasonCopy
  if (statusReasonCopy) {
    stageCopy.description = statusReasonCopy;
  }

  return stageCopy;
}

export function getNotAccessibleCopies(variant: string) {
  if (
    !variant ||
    !notAccessibleCopies[variant as keyof typeof notAccessibleCopies]
  ) {
    captureException({
      error: new Error(
        `Error retrieving the copies for the variant ${variant}. The key was not found.`
      ),
      extra: {
        hint: 'Check the variant key is included in the copies.tsx file'
      }
    });
    return {
      title: notAccessibleCopies.generic.title,
      description: notAccessibleCopies.generic.description
    };
  }

  return {
    title:
      notAccessibleCopies[variant as keyof typeof notAccessibleCopies].title,
    description:
      notAccessibleCopies[variant as keyof typeof notAccessibleCopies]
        .description
  };
}

export function getMenuItemsCopies(route: RoutePath): string {
  return menuItems[route] || route;
}

export function getAppCopies(copyKey: string) {
  if (!copyKey || !appCopies[copyKey as keyof typeof appCopies]) {
    captureException({
      error: new Error(
        `Error retrieving the copies for the key ${copyKey}. The key was not found.`
      ),
      extra: {
        hint: 'Check the key is included in the copies.tsx file'
      }
    });
    return {
      title: '',
      description: ''
    };
  }

  return appCopies[copyKey as keyof typeof appCopies];
}

export function getRandomSuggestions(copyKey: string, single?: boolean) {
  if (
    !copyKey ||
    !randomSuggestions[copyKey as keyof typeof randomSuggestions]
  ) {
    captureException({
      error: new Error(
        `Error retrieving the copies for the key ${copyKey}. The key was not found.`
      ),
      extra: {
        hint: 'Check the key is included in the copies.tsx file'
      }
    });
    return null;
  }

  // Return only one random picked suggestion
  if (single) {
    return randomSuggestions[copyKey as keyof typeof randomSuggestions][
      Math.floor(
        Math.random() *
          randomSuggestions[copyKey as keyof typeof randomSuggestions].length
      )
    ];
  }

  // Return all suggestions to able to control repetitions in the final component
  return randomSuggestions[copyKey as keyof typeof randomSuggestions];
}

export function getRegisterLoginCopies(copyKey: string) {
  if (!copyKey || !(copyKey as keyof typeof registerLoginCopies)) {
    captureException({
      error: new Error(
        `Error retrieving the copies for the key ${copyKey}. The key was not found.`
      ),
      extra: {
        hint: 'Check the key is included in the copies.tsx file'
      }
    });
    return registerLoginCopies['login'];
  }

  return registerLoginCopies[copyKey as keyof typeof registerLoginCopies];
}
