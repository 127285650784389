import { MappedQuestion, Question } from '../types/QuestionTypes';
import { filesMapper } from './productMappers';

export function mapQuestion(question: Question): MappedQuestion {
  return {
    id: question.id,
    answer: question.answer || null,
    answeredAt: question.answered_at ? question.answered_at : '',
    createdAt: question.created_at ? question.created_at : '',
    descriptionMarkdown: question.description_markdown || '',
    options: question.options || [],
    question: question.question || '',
    questionMarkdown: question.question_markdown || '',
    questionType: question.question_type,
    required: !question.optional,
    fileUploadsEnabled: Boolean(question.file_uploads_enabled),
    files: question.files ? filesMapper(question.files) : [],
    trackingEventId: question.tracking_event_id || ''
  };
}

export function questionsMapper(questions: Question[]): MappedQuestion[] {
  return questions.map((question) => {
    return mapQuestion(question);
  });
}
