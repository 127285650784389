import { UpdateItem } from '..';

import { MappedUpdate } from '../../types/UpdateTypes';

const UpdatesContent = ({
  updates = [],
  onUpdate
}: {
  updates: MappedUpdate[];
  onUpdate: () => void;
}) => {
  return (
    <>
      {updates.map((update) => (
        <UpdateItem key={update.id} update={update} onUpdate={onUpdate} />
      ))}
    </>
  );
};

export default UpdatesContent;
