import { createContext } from 'react';

import { ModalType } from '../providers/GlobalModalProvider';

export interface GlobalModalContextType {
  openModal: (modalType: ModalType, modalProps?: any) => void;
  closeModal: () => void;
  store: any;
}

const GlobalModalContext = createContext<GlobalModalContextType>({
  openModal: () => {},
  closeModal: () => {},
  store: null
});

export default GlobalModalContext;
