import { useContext, useEffect, useState } from 'react';

import { Breadcrumb, Button, Text } from '@cavela/ui';

import { FloatingBox, ShaderGradient, StepProductLLMQuestions } from '..';
import i18next from 'i18next';
import useUserInfo from 'src/hooks/useUserInfo';

import { TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';

import ProductContext from '../../context/Product';
import useQuestions from '../../hooks/useQuestions';
import { trackOnboardingStep } from '../../services/analytics/analytics';
import {
  ONBOARDING_PRODUCT_QUESTIONS_COMPLETED,
  ONBOARDING_PRODUCT_QUESTIONS_STARTED
} from '../../services/analytics/eventCatalog';
import { MappedQuestion } from '../../types/QuestionTypes';
import { removeNotRepliedQuestions } from '../../utils/removeNotRepliedQuestions';

const TRANSITION_DURATION = 500;

const { t } = i18next;

export default function StepProductQuestions({
  onNext
}: {
  isFirst: boolean;
  onNext: () => void;
  disabled: boolean;
}) {
  const { userInfo } = useUserInfo();
  const { productDetail, refetch } = useContext(ProductContext);
  const { sendMultipleAnswers, error, loading } = useQuestions();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [isTracking, setIsTracking] = useState(false);
  const [isFormLoaded, setIsFormLoaded] = useState(false);
  const [isLLMOnboarding, setIsLLMOnboarding] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onboardingQuestions = productDetail?.stages[0].questions || [];

  useEffect(() => {
    trackOnboardingStep(ONBOARDING_PRODUCT_QUESTIONS_STARTED);
  }, []);

  useEffect(() => {
    if (!isFormLoaded && !loading) {
      const timeoutId = setTimeout(() => {
        setIsFormLoaded(true);
      }, TRANSITION_DURATION * 4);

      return () => clearTimeout(timeoutId);
    }
  }, [isFormLoaded, loading]);

  // GA4 track questions

  useEffect(() => {
    if (!isTracking) {
      onboardingQuestions
        .map((question, index) => (question.trackingEventId ? index : -1))
        .filter((index) => index !== -1)
        .forEach((index) => {
          const trackingEventId = onboardingQuestions[index].trackingEventId;

          if (trackingEventId) {
            trackOnboardingStep(trackingEventId);
          }
        });

      setIsTracking(true);
    }
  }, [onboardingQuestions, isTracking]);

  const initialFormProps = () => {
    const initialValues: { [key: string]: string } = {};
    const validations: { [key: string]: (value: string) => string | null } = {};

    onboardingQuestions.forEach((question: MappedQuestion, index) => {
      initialValues[`${index}`] = Array.isArray(question.answer)
        ? question.answer.join(', ')
        : question.answer || '';

      if (question.required) {
        validations[`${index}`] = (value) => {
          return value ? null : 'This field is required';
        };
      }
    });

    return { initialValues, validations };
  };

  const onLLMOnboarding = () => {
    setIsLLMOnboarding(true);
  };

  const onSubmit = async () => {
    const isFeatureFlagged = userInfo?.isStaff;

    if (!mantineForm.isDirty()) {
      return isFeatureFlagged ? onLLMOnboarding() : onNext();
    }

    await sendMultipleAnswers({
      productId: productDetail?.productId || '',
      stageId: productDetail?.stages[0].id || '',
      answers: removeNotRepliedQuestions(mantineForm.values)
    });

    if (!error) {
      trackOnboardingStep(ONBOARDING_PRODUCT_QUESTIONS_COMPLETED);
      refetch();

      return isFeatureFlagged ? onLLMOnboarding() : onNext();
    }
  };

  const onClickBack = () => {
    const questionIndex = currentQuestionIndex - 1;

    if (questionIndex < 0) return;

    setCurrentQuestionIndex(questionIndex);
  };

  const onClickNext =
    (required = false) =>
    () => {
      const value = mantineForm.getValues()[currentQuestionIndex];
      const isLastQuestion =
        currentQuestionIndex === onboardingQuestions.length - 1;
      const isFormValid = mantineForm.isValid();
      const isInputValid = isLastQuestion ? isFormValid : Boolean(value);
      const questionIndex = currentQuestionIndex + 1;

      if (isLastQuestion && isFormValid) {
        return onSubmit();
      }

      if (!required || isInputValid) {
        setCurrentQuestionIndex(questionIndex);
      }
    };

  const onKeyDown =
    (required = false) =>
    ({ keyCode, target }: any) => {
      if (keyCode === 13 && (!required || isInputValid)) {
        return onClickNext(required)();
      }

      mantineForm.setValues({
        [currentQuestionIndex]: (target as HTMLInputElement).value
      });
    };

  const formProps = initialFormProps();

  const mantineForm = useForm({
    initialValues: formProps.initialValues,
    validate: formProps.validations
  });

  const isLastQuestion =
    currentQuestionIndex === onboardingQuestions.length - 1;
  const isFormValid = mantineForm.isValid();

  const isInputValid = isLastQuestion
    ? isFormValid
    : Boolean(mantineForm.getValues()[currentQuestionIndex]);

  const currentValue = mantineForm.getInputProps(`${currentQuestionIndex}`);
  const heading = t('createRFQ');

  return isLLMOnboarding ? (
    <StepProductLLMQuestions onNext={onNext} />
  ) : (
    <div className="flex flex-col md:flex-rowitems-center space-between gap-2">
      <div className="bg-white w-full h-full md:w-auto md:h-auto md:rounded-none fixed z-50 right-[50%] left-0 top-0 bottom-0 p-4">
        <div className="absolute left-0 right-0 top-0 h-64 p-4 bg-gradient-to-b from-white from-40% z-50">
          <Breadcrumb />
          <div className="my-4">
            <Text heading>{heading}</Text>
          </div>
        </div>
        <div className="flex flex-col items-start justify-center w-[75%] h-full gap-3 mx-auto">
          <form
            onSubmit={(event) => event.preventDefault()}
            className="w-full transition duration-500 ease-in-out"
          >
            {onboardingQuestions.map((question, index) => {
              //
              return (
                <div key={index}>
                  {index === currentQuestionIndex && (
                    <>
                      <div>
                        <Text control style={{ fontSize: '1.5em' }}>
                          {`${question?.questionMarkdown}${question.required ? '*' : ''}`}
                        </Text>
                        <div className="w-full my-4">
                          <TextInput
                            autoFocus
                            data-autofocus
                            flex={1}
                            size="lg"
                            w="100%"
                            onKeyDown={onKeyDown(Boolean(question.required))}
                            value={currentValue.value}
                            {...currentValue}
                          />
                        </div>
                      </div>
                      <div className="flex">
                        <Button
                          variant="outline"
                          onClick={
                            currentQuestionIndex === 0
                              ? () => (window.location.href = '/new-product')
                              : onClickBack
                          }
                        >
                          {t('back')}
                        </Button>
                        <Button
                          appearance={
                            question.required && !isInputValid
                              ? 'disabled'
                              : 'primary'
                          }
                          disabled={question.required && !isInputValid}
                          onClick={onClickNext(question.required)}
                        >
                          {t('next')}
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              );
            })}
          </form>
        </div>
      </div>
      <div className="hidden md:block bg-black w-auto h-auto rounded-none left-0 right-0 fixed left-[50%] right-0 top-0 bottom-0">
        <div className="absolute w-full h-full z-50 text-center flex flex-col items-center justify-center gap-2">
          <FloatingBox />
          <Text control color="white" style={{ fontSize: '1.25em' }}>
            Your Product
          </Text>
        </div>
        <ShaderGradient />
      </div>
    </div>
  );
}
