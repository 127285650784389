import ReactDOM from 'react-dom/client';

import App from './App';
// i18n and other copy configuration
import './data/copies';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(<App />);
