import { MappedLifecycleStage } from '../types/ProductTypes';

// "current stage": the first stage that is not "done", or the last one of the flow if all are.
// This works under the assumption that there's no "intermediate" pending stage.

export default function getLastActiveStage({
  productStages
}: {
  productStages: MappedLifecycleStage[];
}) {
  return productStages[
    getLastActiveStageIndex({ productStages: productStages })
  ];
}

export function getLastActiveStageIndex({
  productStages
}: {
  productStages: MappedLifecycleStage[];
}) {
  const index = productStages.findIndex((stage) => stage.status !== 'done');
  if (index < 0) {
    return productStages.length - 1;
  }
  return index;
}
