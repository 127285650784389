import React, { useContext, useState } from 'react';

import { Label, Text } from '@cavela/ui';
import {
  CheckRounded,
  NotificationsRounded,
  ReportProblemRounded,
  ShoppingBagRounded,
  StorefrontRounded
} from '@mui/icons-material';

import {
  CustomMarkdownWithGallery,
  DynamicHtmlContent,
  QuestionContent,
  Timestamp
} from '..';
import AuthContext from 'src/context/Auth';
import { API_URL } from 'src/settings';
import { fetchWithAuth } from 'src/utils/fetchWithAuth';

import useQuestions from '../../hooks/useQuestions';
import { customAnchor, quoteMarkdown } from '../../styles/markdownMapper';
import { SendQuestionResponse } from '../../types/QuestionTypes';
import { MappedUpdate } from '../../types/UpdateTypes';

interface Answer {
  stageId: string;
  questionId: number;
  values: string | string[];
}

interface UpdateItemProps {
  update: MappedUpdate;
  onUpdate: () => void;
}

const ONBOARDING = 'Onboarding';

export const UpdateItem: React.FC<UpdateItemProps> = ({ update, onUpdate }) => {
  const { sendAnswer } = useQuestions();
  const { userInfo } = useContext(AuthContext);
  const [relevant, setRelevant] = useState(update.relevant);

  const onAnswer = async (values: Answer): Promise<SendQuestionResponse> => {
    if (update.product.id) {
      const answerData = {
        productId: update.product.id,
        stageId: values.stageId,
        questionId: values.questionId,
        answer: values.values
      };

      const response = await sendAnswer(answerData);

      onUpdate();

      return { error: response.error || '' };
    }

    return { error: 'Product ID not found' };
  };

  const isQuestion = update.question && update.questionId !== null;
  const isAnswered = Boolean(update?.question?.answer);

  const answerDate =
    isAnswered && update?.question?.answeredAt
      ? new Date(update.question.answeredAt)
      : new Date(update.createdAt);

  const { supplierCloak = ONBOARDING } = update || {};

  const markAsRelevant = async () => {
    if (!userInfo) return;

    const url = `${API_URL}/api/users/${userInfo.id}/updates/${update.id}`;

    const response = await fetchWithAuth(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ relevant: !relevant })
    });

    const data = await response.json();
    setRelevant(data.relevant);
  };

  return (
    <div
      className="flex flex-col gap-2 bg-white rounded-md shadow-sm my-2 p-4"
      key={update.createdAt}
    >
      <div className="flex items-center justify-between">
        <div className="flex flex-col justify-center">
          {isAnswered ? (
            <Label variant="success">
              <CheckRounded fontSize="small" />
              Completed
            </Label>
          ) : (
            <Label variant={isQuestion ? 'caution' : 'alert'}>
              {isQuestion ? (
                <ReportProblemRounded fontSize="small" />
              ) : (
                <NotificationsRounded fontSize="small" />
              )}
              {isQuestion ? 'Action Required' : 'New Update'}
            </Label>
          )}
        </div>
        <div className="flex-1 flex flex-col justify-center">
          {answerDate && <Timestamp date={answerDate} />}
        </div>
      </div>
      <div className="flex gap-1 capitalize">
        {supplierCloak === ONBOARDING ? (
          <ShoppingBagRounded
            htmlColor="var(--mantine-color-cavela-shadow-6)"
            fontSize="small"
          />
        ) : (
          <StorefrontRounded
            htmlColor="var(--mantine-color-cavela-shadow-6)"
            fontSize="small"
          />
        )}
        <Text
          control
          color="var(--mantine-color-cavela-shadow-6)"
          style={{ fontSize: '0.9em' }}
        >
          {supplierCloak}
        </Text>
      </div>
      <Text control style={{ fontSize: '1.25em' }}>
        {update.title}
      </Text>
      {update.question && update.questionId !== null ? (
        <div className="flex flex-col w-full">
          <QuestionContent
            questionContent={update.question}
            productId={update.product.id}
            stageId={update.productStage}
            questionId={update.questionId}
            onQuestionSend={onAnswer}
          />
        </div>
      ) : (
        <div className="flex flex-col w-full">
          {update.message ? (
            <CustomMarkdownWithGallery
              components={{
                p: (props: any) => <Text small>{props.children}</Text>,
                div: quoteMarkdown,
                a: customAnchor
              }}
            >
              {update.message}
            </CustomMarkdownWithGallery>
          ) : (
            <DynamicHtmlContent content={update.messageHtml} />
          )}
        </div>
      )}

      <div className="flex justify-start">
        <button
          className={`px-2 py-1 border border-[#00EF] rounded-full
      hover:scale-105 transition-all duration-300 ${!relevant ? 'bg-[#00EF]' : ''}
    `}
          onClick={markAsRelevant}
        >
          👍
        </button>
      </div>
    </div>
  );
};

export default UpdateItem;
