import { IconInfoCircle } from '@tabler/icons-react';
import { useContext } from 'react';

import i18next from 'i18next';

import { Flex } from '@mantine/core';

import ProductContext from '../../context/Product';
import useQuestions from '../../hooks/useQuestions';
import {
  MappedQuestion,
  SendQuestionResponse
} from '../../types/QuestionTypes';
import { getQuestionsCopies } from '../../utils/copiesGetters';
import StageSectionWrapper from '../StageSectionWrapper/StageSectionWrapper';
import QuestionContent from './QuestionContent';

const { t } = i18next;

interface QuestionContainerProps {
  productId: string;
  stageId: string;
  questions: MappedQuestion[];
}

interface handleQuestionSendProps {
  stageId: string;
  questionId: number;
  values: string | string[];
  questionType: string;
}

export default function QuestionsContainer({
  questions,
  stageId
}: QuestionContainerProps) {
  const { sendAnswer } = useQuestions();
  const { refetch, productDetail } = useContext(ProductContext);
  const { productId = null } = productDetail || {};

  async function handleQuestionSend(
    values: handleQuestionSendProps
  ): Promise<SendQuestionResponse> {
    if (productId) {
      const answerData = {
        productId,
        stageId: values.stageId,
        questionId: values.questionId,
        answer: values.values
      };
      const response = await sendAnswer(answerData);

      if (!response.error) {
        refetch();
      }

      return { error: response.error || '' };
    }
    return { error: 'Product ID not found' };
  }

  return (
    <StageSectionWrapper sectionTitle={t('questionsSectionTitle')}>
      <Flex flex={1} direction="column" gap={16}>
        {questions.map((question, index) => {
          return (
            <QuestionContent
              key={stageId + index}
              questionContent={question}
              productId={productId}
              stageId={stageId}
              questionId={index}
              onQuestionSend={handleQuestionSend}
            />
          );
        })}
      </Flex>

      <Flex gap="8" align="flex-start">
        <IconInfoCircle
          size={24}
          color="var(--mantine-color-cavela-success-9)"
        ></IconInfoCircle>
        {getQuestionsCopies().disclaimer}
      </Flex>
    </StageSectionWrapper>
  );
}
