import ReactGA from 'react-ga4';

import { UserInfo } from '../../hooks/useUserInfo';
import { ANALYTICS_MEASUREMENT_ID } from '../../settings';
import { info } from '../logger';
import { QUESTION_ANSWERED } from './eventCatalog';

// Add this switch for debugging because this can be very noisy.
const SILENCED_LOGGING = true;

function pathMapper(path: string) {
  switch (path) {
    case '/':
      path = 'app-home';
      break;
    default:
      if (path.includes('/products/')) {
        path = `products/:productId`;
      }
      break;
  }
  return path;
}
//@TODO add notstaffkey when finish testing
const testingUsers = ['notstaff', 'preview', 'fullfeatured'];

export function init({ userInfo }: { userInfo?: UserInfo | null }) {
  if (userInfo) {
    const { username, isStaff, impersonating } = userInfo;
    const enableAnalytics =
      !isStaff &&
      !impersonating &&
      username &&
      !testingUsers.includes(username);
    if (ANALYTICS_MEASUREMENT_ID && enableAnalytics) {
      //Assuming that we don't track anything if there's no user logged in.
      ReactGA.initialize(ANALYTICS_MEASUREMENT_ID);
      ReactGA.set({ user_id: username });
      return (
        !SILENCED_LOGGING &&
        info(
          `[Analytics] GA initialized with measurement ID [${ANALYTICS_MEASUREMENT_ID}]`
        )
      );
    }
    return info(
      `[Analytics] GA not initialized - isStaff: ${isStaff} - isImpersonating: ${JSON.stringify(impersonating)} - isTestingUser: ${testingUsers.includes(username)}`
    );
  }

  return info(`[Analytics] GA not initialized - no user info provided`);
}

export function sendEvent(
  eventName: string,
  eventParams?: { [key: string]: any }
) {
  if (ANALYTICS_MEASUREMENT_ID) {
    ReactGA.event(eventName, eventParams);
    return (
      !SILENCED_LOGGING &&
      info(
        `[Analytics] event ${eventName}-${eventParams ? JSON.stringify(eventParams) : ''} sent`
      )
    );
  }
  return !SILENCED_LOGGING && info(`[Analytics] event ${eventName} NOT sent`);
}

export function trackQuestionAnswered(origin: string) {
  sendEvent(QUESTION_ANSWERED, { origin: pathMapper(origin) });
}

export function trackOnboardingStep(stepName: string, stepData?: any) {
  sendEvent(stepName, stepData);
}

export function trackPageView(path: string) {
  if (ANALYTICS_MEASUREMENT_ID) {
    ReactGA.send({
      hitType: 'pageview',
      page: pathMapper(path),
      title: document.title
    });
    return (
      !SILENCED_LOGGING &&
      info(`[Analytics] page view sent: ${pathMapper(path)}`)
    );
  }
}
