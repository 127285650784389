import React from 'react';

import { Image } from '@mui/icons-material';

import Badge from '../Badge/Badge';
import useLogic from './logic';
import { RfqDraftCardProps } from './props';

const RfqDraftCard = ({
  id,
  image,
  documentName,
  questionsLength,
  createdAt,
  redirectToRfq
}: RfqDraftCardProps) => {
  const { formatDate } = useLogic();

  return (
    <div
      key={id}
      className="bg-white shadow-lg rounded-2xl p-4 relative hover:animate-hover transition-all duration-300 cursor-pointer h-[380px]"
      onClick={() => redirectToRfq(id)}
    >
      <div className="flex flex-col h-full">
        {image ? (
          <img
            src={image}
            alt={documentName}
            className="w-full h-[160px] object-cover mb-4 rounded-lg"
          />
        ) : (
          <div className="w-full h-[160px] flex items-center justify-center bg-[#D3D5D9] rounded-lg mb-4">
            <Image htmlColor="#6E747D" fontSize="large" />
          </div>
        )}

        <div className="mb-4">
          {' '}
          <Badge variant="draft" text="Draft" />
        </div>

        <h3 className="text-xl font-bold text-black mb-2 overflow-hidden whitespace-normal break-words pb-2">
          {documentName}
        </h3>

        <div className="mt-auto mb-0.5">
          <p className="text-[#6E747D] mb-2">
            Progress: {questionsLength} answers
          </p>

          <div className="flex items-center">
            <img src="/icons/calendar.svg" alt="calendar" className="mr-2" />
            <p className="text-gray-600">Created: {formatDate(createdAt)}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RfqDraftCard;
