import { Suspense, useContext, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import AuthContext from '../../context/Auth';
import { GlobalModal } from '../../providers/GlobalModalProvider';
import * as Analytics from '../../services/analytics/analytics';

export const FOOTER_HEIGHT = 50;

const PageLayout = () => {
  const location = useLocation();
  useEffect(() => {
    Analytics.trackPageView(location.pathname);
  }, [location]);

  const { userInfo } = useContext(AuthContext);

  Analytics.init({ userInfo });

  return (
    <>
      <GlobalModal>
        <Suspense>
          <Outlet />
        </Suspense>
      </GlobalModal>
    </>
  );
};

export default PageLayout;
