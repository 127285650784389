import { MAX_FILE_UPLOAD_SIZE_MB } from '../settings';

export function bytesToMbs({ bytes }: { bytes: number }) {
  if (bytes < 0 || isNaN(bytes) || !isFinite(bytes)) {
    return 0;
  }
  const megabytes = bytes / (1024 * 1024);
  const roundedMegabytes = megabytes.toFixed(2);
  return Number(roundedMegabytes);
}

export function isOverLimitedMb({ bytes }: { bytes: number }) {
  if (bytes < 0 || isNaN(bytes) || !isFinite(bytes)) {
    return false;
  }

  return bytes > 1024 * 1024 * MAX_FILE_UPLOAD_SIZE_MB;
}
