import {
  ActionIcon,
  Anchor,
  Blockquote,
  Button,
  createTheme,
  CSSVariablesResolver,
  defaultVariantColorsResolver,
  MantineColorsTuple,
  parseThemeColor,
  Text,
  TextInput,
  VariantColorsResolver
} from '@mantine/core';

import actionIconVariants from './actionIconVariants.module.css';
import anchor from './anchor.module.css';
import blockquote from './blockquote.module.css';
import buttonVariants from './buttonVariants.module.css';
import textInputVariants from './textInputVariants.module.css';
import textVariants from './textVariants.module.css';

export const cavelaPrimary: MantineColorsTuple = [
  '#E1E8FF',
  '#C3CEF6',
  '#ADBCF2',
  '#8099EC',
  '#5475E5',
  '#000EEF',
  '#000EEF',
  '#000EEF',
  '#2D68F8',
  '#1C3FB7',
  '#1C3FB7'
];

export const cavelaCaution: MantineColorsTuple = [
  '#FFF0E9',
  '#FDE5D8',
  '#FBD5C0',
  '#F8B490',
  '#F59E0B',
  '#F59E0B',
  '#F59E0B',
  '#F59460',
  '#F27430',
  '#E1580E'
];

export const cavelaSuccess: MantineColorsTuple = [
  '#DAF8E6',
  '#ACEFC8',
  '#82E6AC',
  '#2CD673',
  '#2CD673',
  '#2CD673',
  '#22AD5C',
  '#22AD5C',
  '#22AD5C',
  '#1A8245'
];

export const cavelaError: MantineColorsTuple = [
  '#FEF3F3',
  '#FEF3F3',
  '#FEF3F3',
  '#FEF3F3',
  '#FEF3F3',
  '#E10E0E',
  '#E10E0E',
  '#E10E0E',
  '#E10E0E',
  '#E10E0E'
];

export const cavelaShadow: MantineColorsTuple = [
  '#ffffff',
  '#f3f4f6',
  '#e5e7eb',
  '#cdcdcd',
  '#b2b2b2',
  '#9a9a9a',
  '#8b8b8b',
  '#848484',
  '#6E747D',
  '#434242',
  '#0B0B0B'
];

export const cavelaGlass: MantineColorsTuple = [
  'transparent',
  '#00000010',
  '#00000020',
  '#00000030',
  '#00000040',
  '#00000050',
  '#00000060',
  '#00000070',
  '#00000080',
  '#00000090',
  '#000000'
];

export const cavelaSheer: MantineColorsTuple = [
  'transparent',
  '#ffffff10',
  '#ffffff20',
  '#ffffff30',
  '#ffffff40',
  '#ffffff50',
  '#ffffff60',
  '#ffffff70',
  '#ffffff80',
  '#ffffff90',
  '#ffffff'
];

const variantColorResolver: VariantColorsResolver = (input) => {
  const defaultResolvedColors = defaultVariantColorsResolver(input);
  const parsedColor = parseThemeColor({
    color: input.color || input.theme.primaryColor,
    theme: input.theme
  });

  // Override some properties for variant
  if (
    parsedColor.isThemeColor &&
    parsedColor.color === 'cavela-primary' &&
    input.variant === 'outline'
  ) {
    return {
      ...defaultResolvedColors,
      color: 'var(--mantine-color-cavela-primary-7)',
      hoverColor: 'var(--mantine-color-cavela-primary-0)',
      hover: 'var(--mantine-color-cavela-primary-5)'
    };
  }

  return defaultResolvedColors;
};

export const resolver: CSSVariablesResolver = (theme) => ({
  variables: {},
  light: {
    '--mantine-color-body': 'cavela-caution.0'
  },
  dark: {}
});

export const theme = createTheme({
  colors: {
    'cavela-primary': cavelaPrimary,
    'cavela-caution': cavelaCaution,
    'cavela-success': cavelaSuccess,
    'cavela-error': cavelaError,
    'cavela-shadow': cavelaShadow,
    'cavela-glass': cavelaGlass,
    'cavela-sheer': cavelaSheer
  },
  components: {
    ActionIcon: ActionIcon.extend({
      classNames: actionIconVariants
    }),
    Button: Button.extend({
      classNames: buttonVariants
    }),
    Anchor: Anchor.extend({
      classNames: anchor
    }),
    Blockquote: Blockquote.extend({
      classNames: blockquote
    }),
    Text: Text.extend({
      classNames: textVariants
    }),
    TextInput: TextInput.extend({
      classNames: textInputVariants
    })
  },
  headings: {
    sizes: {
      h1: { fontSize: '1.6em' },
      h2: { fontSize: '1.6em', fontWeight: '400' },
      h3: { fontSize: '1.2em', fontWeight: '400' },
      h4: { fontSize: '1.2em', fontWeight: '400' },
      h5: { fontSize: '1em', fontWeight: '400' },
      h6: { fontSize: '1em', fontWeight: '400' }
    }
  },
  primaryColor: 'cavela-primary',
  fontFamily: 'Poppins, sans-serif',
  black: cavelaShadow[9],
  variantColorResolver
});
