import { HTMLAttributeAnchorTarget } from 'react';

import i18next from 'i18next';

import { Anchor, AnchorProps, Text } from '@mantine/core';

import styles from '../../styles/anchor.module.css';

const { t } = i18next;

interface Props extends AnchorProps {
  target?: HTMLAttributeAnchorTarget;
  rel?: string;
  href?: string;
  children?: any;
  className?: string;
}

const Link = ({
  target = t('aBlankAttr') || '',
  rel = t('defaultARelAttr') || '',
  href = t('hashtag') || '',
  className = styles?.customAnchor,
  children,

  ...props
}: Props) => {
  const relAttr = target === t('aBlankAttr') ? t('defaultARelAttr') || '' : rel;

  return (
    <Anchor
      target={target}
      rel={relAttr}
      href={href}
      className={className}
      {...props}
    >
      <Text
        c={props.c || 'var(--mantine-color-cavela-primary-5)'}
        td="underline"
        component="span"
      >
        {children}
      </Text>
    </Anchor>
  );
};

export default Link;
