import { useContext, useEffect, useState } from 'react';

import { Button, Navigation } from '@cavela/ui';
import { Add, Menu, PersonRounded, ShoppingBag } from '@mui/icons-material';

import useFeatureFlag from 'src/hooks/useFeatureFlag';

import AuthContext from '../../context/Auth';

const SidebarNav = () => {
  const { pathname } = window.location;
  const isProducts = pathname === '/' || pathname.match(/product/);
  const isProfile = pathname.match(/profile/);
  const { userInfo } = useContext(AuthContext);
  const { isFeatureEnabled: isNewRfqFlowEnabled } =
    useFeatureFlag('new_rfq_flow');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [, setWasMenuOpen] = useState(false);

  useEffect(() => {
    setWasMenuOpen(isMenuOpen);
  }, [isMenuOpen]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <div className="lg:hidden absolute ml-10 mt-4 z-50">
        <button
          onClick={toggleMenu}
          className="fixed bottom-4 left-4 bg-[#030FEF] rounded-lg p-2 z-10"
        >
          <Menu htmlColor="white" />
        </button>
      </div>
      <div
        className={`w-72 -ml-72 lg:ml-0 transition-all duration-500 ease-in-out ${isMenuOpen ? 'ml-0' : ''} lg:block ${isMenuOpen ? 'absolute inset-0 z-40 bg-white' : 'hidden'}`}
      >
        <Navigation
          companyName={userInfo?.username}
          companyHref="/profile"
          userEmail={userInfo?.email}
          avatarSrc={userInfo?.avatar}
          nav={[
            {
              path: '/',
              text: (
                <span className="flex">
                  <ShoppingBag
                    htmlColor={
                      isProducts
                        ? 'var(--mantine-color-cavela-primary-5)'
                        : 'black'
                    }
                    fontSize="medium"
                  />
                  &nbsp;
                  {isProducts ? (
                    <span className="text-[var(--mantine-color-cavela-primary-5)]">
                      Products
                    </span>
                  ) : (
                    <span className="text-black">Products</span>
                  )}
                </span>
              )
            },
            {
              path: '/profile',
              text: (
                <span className="flex">
                  <PersonRounded
                    htmlColor={
                      isProfile
                        ? 'var(--mantine-color-cavela-primary-5)'
                        : 'black'
                    }
                    fontSize="medium"
                  />
                  &nbsp;
                  {isProfile ? (
                    <span className="text-[var(--mantine-color-cavela-primary-5)]">
                      Account
                    </span>
                  ) : (
                    <span className="text-black">Account</span>
                  )}
                </span>
              )
            }
          ]}
          actions={[
            <>
              {!isNewRfqFlowEnabled && (
                <div
                  data-testid="create-new-product-button"
                  key="new-product"
                  id="create-old-product-button"
                >
                  <Button
                    full
                    key="new-product"
                    type="primary"
                    onClick={() => (window.location.href = '/new-product')}
                    style={{ margin: 0, width: '100%' }}
                  >
                    <Add fontSize="small" />
                    &nbsp; New Product
                  </Button>
                </div>
              )}

              {isNewRfqFlowEnabled && (
                <div
                  data-testid="create-new-rfq-button"
                  key="new-rfq"
                  className="mt-4"
                  id="create-new-rfq-button"
                >
                  <Button
                    full
                    key="new-rfq"
                    type="primary"
                    onClick={() => (window.location.href = '/new-rfq')}
                    style={{ margin: 0, width: '100%' }}
                  >
                    <Add fontSize="small" />
                    &nbsp;New Product
                  </Button>
                </div>
              )}
            </>
          ]}
        />
      </div>
    </>
  );
};

export default SidebarNav;
