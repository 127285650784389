import * as reactSpring from '@react-spring/three';
import * as drei from '@react-three/drei';
import * as fiber from '@react-three/fiber';

import {
  ShaderGradient as ReactShaderGradient,
  ShaderGradientCanvas as ReactShaderGradientCanvas
} from 'shadergradient';

const ShaderGradient = () => (
  <ReactShaderGradientCanvas
    importedFiber={{ ...fiber, ...drei, ...reactSpring }}
    style={{
      position: 'absolute',
      top: 0
    }}
  >
    <ReactShaderGradient
      control="props"
      color1="#5d00ce"
      color2="#00063f"
      color3="#000000"
      grain="on"
      envPreset="city"
      lightType="3d"
      shader="defaults"
      type="waterPlane"
      range="enabled"
      rangeStart={0}
      rangeEnd={1000}
      toggleAxis={false}
      wireframe={false}
      frameRate={10}
      brightness={0.75}
      cameraZoom={1}
      uAmplitude={0}
      uDensity={1.1}
      uFrequency={0}
      uSpeed={0.07}
      uStrength={2.4}
      uTime={0.2}
      cAzimuthAngle={90}
      cDistance={3.9}
      cPolarAngle={115}
      positionX={-0.5}
      positionY={0.1}
      positionZ={0}
      rotationX={50}
      rotationY={0}
      rotationZ={235}
      zoomOut={false}
    />
  </ReactShaderGradientCanvas>
);

export default ShaderGradient;
