import { useState } from 'react';

import ContactForm from '../components/ContactForm/ContactForm';

import GlobalModalContext from '../context/GlobalModal';

export type ModalType = 'CONTACT';

export const MODAL_TYPES: { [key: string]: ModalType } = {
  CONTACT: 'CONTACT'
};

const modalMapper: { [key: string]: () => JSX.Element } = {
  [MODAL_TYPES.CONTACT]: ContactForm
};

/**
 * Helps handeling the open and close of modals from anywhere in the app.
 * Specially for the modals that are not directly related to the component
 * or can be called from different components.
 * It can be used for all the modals in the app.
 */
export const GlobalModal = ({ children }: { children: any }) => {
  const [store, setStore] = useState({ modalType: '', modalProps: {} });
  const { modalType, modalProps } = store || {
    modalType: '',
    modalProps: {}
  };

  /**
   *
   * @param modalType: corresponds to the modalType to be rendered. Check allowed values in MODAL_TYPES
   * @param modalProps: any additional props that needs to be passed to the modal component. They'll be passed directly to the component assigned to this kind of modal.
   */
  const openModal = (modalType: ModalType, modalProps: any = {}) => {
    setStore({
      ...store,
      modalType,
      modalProps
    });
  };

  const renderComponent = () => {
    const ModalComponent = modalMapper[modalType];
    if (!modalType || !ModalComponent) {
      return null;
    }
    return <ModalComponent {...modalProps} />;
  };

  const closeModal = () => {
    setStore({
      ...store,
      modalType: '',
      modalProps: {}
    });
  };

  return (
    <GlobalModalContext.Provider value={{ store, openModal, closeModal }}>
      {renderComponent()}
      {children}
    </GlobalModalContext.Provider>
  );
};
