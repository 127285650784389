import { Textarea } from '@mantine/core';

export default function TextQuestion(questionProps: any) {
  const { sendLoading, isAnswered, ...rest } = questionProps;

  return (
    <Textarea
      size="md"
      type="text"
      styles={{
        input: {
          resize: 'vertical'
        }
      }}
      {...rest}
      disabled={sendLoading || isAnswered}
      readOnly={isAnswered}
      required
      data-testid="text-question"
    />
  );
}
