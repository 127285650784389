import { Navigate, useLocation } from 'react-router-dom';

export default function StepProductFinal() {
  const location = useLocation();

  return (
    <div>
      <Navigate to="/" state={{ from: location }} replace />;
    </div>
  );
}
