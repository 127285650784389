export function removeNotRepliedQuestions(answers: { [key: string]: string }): {
  [key: string]: string;
} {
  return Object.keys(answers).reduce((acc: { [key: string]: string }, key) => {
    if (answers[key] !== '') {
      acc[key] = answers[key];
    }
    return acc;
  }, {});
}
