import { MappedUpdate, Update } from '../types/UpdateTypes';
import { mapQuestion } from './questionMapper';

export default function updatesMapper(updates: Update[]): MappedUpdate[] {
  return updates.map((update) => {
    return {
      id: update.id,
      updateType: update.update_type,
      createdAt: update.created_at,
      message: update.message,
      messageHtml: update.message_html,
      questionId: update.question_id,
      productId: update.product_id,
      productStage: update.product_stage,
      relevant: Boolean(update.relevant),
      question: update.question ? mapQuestion(update.question) : null,
      product: {
        id: update.product?.id || null,
        detail: {
          name: update.product?.detail?.name || null
        }
      },
      supplierCloak: update.supplier_cloak?.cloak,
      title: update.title
    };
  });
}
