import { Link as RouterLink } from 'react-router-dom';

import i18next from 'i18next';

import { Text } from '@mantine/core';

import { RoutePath } from '../routes';
import { MAX_FILE_UPLOAD_SIZE_MB, SUPPORT_EMAIL } from '../settings';

const debug = Boolean(process.env.REACT_APP_ENVIRONMENT?.match(/local/));

/**
 * i18n
 */

i18next.init({
  fallbackLng: 'en',
  lng: 'en',
  debug,
  resources: {
    en: {
      translation: {
        back: 'Back',
        cancel: 'Cancel',
        contactUs: 'contact us',
        cancelOnboarding: '✕',
        cancelOnboardingConfirmationDescription:
          'This product and all its data will be deleted.',
        cancelOnboardingConfirmationTitle: 'Are you sure?',
        captionForFile: 'Add a caption for this file',
        chooseFiles: 'Choose files',
        contactFormEmail: 'Your e-mail',
        contactFormError: `Sorry, there was an error sending the message, if the problem persists, please contact us at ${SUPPORT_EMAIL}`,
        contactFormGenericOption: 'Not related to a particular product',
        contactFormMessage: 'What can we help you with?',
        contactFormProduct: 'What product is it about?',
        contactFormConfirmationTitle: 'Message sent!',
        contactFormConfirmationBody:
          'Thank you, we will contact you as soon as possible.',
        createNewProduct: 'Create a new product',
        createNewProductError: 'Error creating the product.',
        createNewProductNameError: 'Product name is invalid.',
        createRFQ: "Let's build your Quote Request 🚀",
        download: 'Download',
        dragDrop: 'Drag and drop files here',
        drop: 'Drop files here',
        addFileError: 'Error adding file(s).',
        errorDeletingProduct:
          'Error deleting the product,, please try again later or contact us.',
        errorFileSizeInline: 'File size exceeds the limit',
        errorGenericTitle: 'Oops!, something went wrong',
        errorLoginOrCreating:
          "There's been an error logging in or creating a user.",
        errorProduct404: "We couldn't find this product",
        errorSendingAnswers:
          'Error sending answers, please try again later or contact us.',
        errorTitleTooLong: 'Title must be at most 255 characters long',
        errorTitleTooShort: 'Title must be at least 3 characters long',
        errorUploadGeneric:
          'Something went wrong. Please try again later or contact us.',
        excedeedFileSizeError: `Some of your files exceed ${MAX_FILE_UPLOAD_SIZE_MB}MB allowed per file, please remove before sending`,
        uploadFiles: 'Upload files',
        addFiles: 'Add Files',
        filesSectionTitle: 'Uploaded Documents',
        finish: 'Finish',
        loading: 'Loading',
        maxSize: `Max size ${MAX_FILE_UPLOAD_SIZE_MB}MB`,
        menuContactUs: 'Contact us',
        menuLogout: 'Log out',
        next: 'Next',
        now: 'just now',
        onboardingConfirmationBack: 'No, I need to go back',
        onboardingConfirmationCTA: "Yes, I'm sure",
        onboardingConfirmationDescription:
          'Are these all the files you need to share for this product before we start sourcing?',
        onboardingConfirmationTitle: 'One last check',
        onboardingFilesStepDescription:
          'Share all relevant files for this product, including descriptions, diagrams, photos and logos.',
        onboardingFilesStepTitle: 'Upload files',
        onboardingFinish: 'Your RFQ for the *{{productTitle}}* has been sent!',
        onboardingFinishDetail:
          'We will get back to you once the RFQ is complete.',
        onboardingNameStepDescription: 'Give your product a name',
        onboardingNameStepTitle: 'Tell us about this product!',
        questionSavedDate: 'You replied to this question on',
        questionTriggerSourcingStarted: 'Sourcing was requested on',
        questionsRepliedOn: 'Replied',
        questionsSectionTitle: 'Questions',
        save: 'Save',
        second: 'second',
        selectedFiles: 'Selected files',
        send: 'Send',
        staffUploaded: 'Uploaded by Cavela',
        startSourcing: 'Start Sourcing',
        unansweredQuestionsError:
          'Please answer and save all questions before proceeding',
        updatesPendingQuestion:
          'Question for product: [{{productName}}](/products/{{productId}}).',
        questionAnswered:
          'Question was answered for product: [{{productName}}](/products/{{productId}}).',
        on: 'on',
        at: 'at',
        continue: 'Continue',
        uploadCTA: 'Upload',
        approved: 'approved!',
        rejected: 'rejected',
        submitSuccessHeading: 'Question answered',
        submitSuccess: 'Thanks for your response!',
        submitErrorHeading: 'Error',
        submitError: 'Unknown error.',
        done: 'Done!',
        closeNotification: 'Close notification',
        exit: 'exit',
        aBlankAttr: '_blank',
        defaultARelAttr: 'noreferrer',
        hashtag: '#',
        editProductTitle: 'Edit product title',
        errorEmailRequired: 'Email is required',
        errorValidEmail: 'Please enter a valid email address',
        enterYourEmail: 'Email',
        expiredCta: 'Refresh token',
        expiredDisclaimer: '',
        expiredFormTitle: 'It seems your token has expired.',
        expiredPromoText1:
          "You're one step away from sourcing with +50,000 premium providers with the simplicity of having a dedicated provider, just for you.",
        expiredPromoText2:
          'No more tedious write-ups. We ingest any information you have available and automatically structure it in a standardized RFQ format optimized to get rapid, accurate supplier responses.',
        expiredToOtherForm:
          "By clicking 'Sign up' you agree to our [terms of service](/register)",
        expiredValidationDescription: '', // API replies with "login", so the validation copies will be the one for login copies.
        expiredValidationTitle: '',
        expiredValidationWarning: '',
        loginCta: 'Sign in',
        loginDisclaimer: '',
        loginFormTitle: 'Sign in',
        loginPromoText1:
          "You're one step away from sourcing with +50,000 premium providers with the simplicity of having a dedicated provider, just for you.",
        loginPromoText2:
          'No more tedious write-ups. We ingest any information you have available and automatically structure it in a standardized RFQ format optimized to get rapid, accurate supplier responses.',
        loginToOtherForm:
          "By clicking 'Sign up' you agree to our [terms of service](https://www.cavela.com/tos)",
        loginValidationDescription:
          'Please check your inbox and use the link in the e-mail to access your account.',
        loginValidationTitle: 'An e-mail with a login link has been sent to',
        loginValidationWarning:
          "If you don't see the e-mail, check your spam folder or ",
        registerCta: 'Sign up',
        registerDisclaimer:
          "By clicking 'Sign up' you agree to our [terms of service](https://www.cavela.com/tos)",
        registerFormTitle: 'Sign up',
        registerPromoText1:
          "You're one step away from sourcing with +50,000 premium providers with the simplicity of having a dedicated provider, just for you.",
        registerPromoText2:
          'No more tedious write-ups. We ingest any information you have available and automatically structure it in a standardized RFQ format optimized to get rapid, accurate supplier responses.',
        registerToOtherForm:
          "By clicking 'Sign up' you agree to our [terms of service](https://www.cavela.com/tos)",
        joinWaitlist:
          "Don't have an access code? [Join waitlist](https://www.cavela.com/#form)",
        goToLogin:
          'Already have an account? [Sign in](https://www.cavela.com/login)',
        registerValidationDescription:
          'Please check your inbox and use the link in the e-mail to access your account.', // API replies with "login", so the validation copies will be the one for login copies.
        registerValidationTitle: 'Your sign-in link has been mailed to',
        registerValidationWarning:
          "If you don't see the e-mail, check your spam folder or ",
        cavelaTitle: 'CAVELA',
        cavela401: 'Expired credentials',
        cavela404: "Oops! This page doesn't exist",
        cavela403: 'Use your magic link to access Cavela',
        cavela500: 'Something went wrong',
        goHome: 'Go back to the homepage',
        checkYourEmail: 'You can find the link in your email.',
        contactProblem: 'If the problem persists, please contact us at',
        contactLink: 'Please contact us to get a new link at',
        products: 'Products',
        updates: 'Updates',
        profile: 'Profile',
        login: 'Login',
        register: 'Register',
        exampleSearch: 'Hat with a leather strap',
        pending: 'Pending',
        ongoing: 'Ongoing',
        blocked: 'Blocked',
        completed: 'Completed',
        documentsPending: 'Documents reception',
        documentsPendingDescription: '',
        documentsOngoing: 'Ongoing RFQ',
        documentsOngoingDescription: '',
        documentsBlocked: 'Awaiting documents',
        documentsBlockedDescription:
          'Upload a detailed description of the product you would like to source.',
        documentsDone: 'Documents received',
        documentsDoneDescription: "We've received your product documents.",
        rfqPending: 'RFQ generation',
        rfqPendingDescription:
          'Once we receive your product documents, we will create a Request for Quote for suppliers.',
        rfqOngoing: 'Generating RFQ',
        rfqOngoingDescription:
          'We are generating your Request for Quote for suppliers.',
        rfqBlocked: 'Pending RFQ',
        rfqBlockedDescription:
          'Resolve the blockers to continue generating your Request for Quote.',
        rfqDone: 'RFQ sent',
        rfqDoneDescription: '',
        quotesPending: 'Quotes reception',
        quotesPendingDescription:
          'Once your Request for Quote is complete, we will send it out for suppliers to produce quotes.',
        quotesOngoing: 'Generating quotes',
        quotesOngoingDescription:
          'Suppliers are generating quotes for your product.',
        quotesBlocked: 'Pending quotes',
        quotesBlockedDescription:
          'Resolve the blockers to obtain quotes from suppliers.',
        quotesDone: 'Quotes received',
        quotesDoneDescription: '',
        sampleOrderPending: 'Samples purchase',
        sampleOrderPendingDescription:
          'Once you have received quotes from suppliers, you will be able to purchase product samples.',
        sampleOrderOngoing: 'Processing sample purchase',
        sampleOrderOngoingDescription: 'Your sample purchase is processing.',
        sampleOrderBlocked: 'Pending samples purchase',
        sampleOrderBlockedDescription:
          'Resolve the blockers to purchase your samples.',
        sampleOrderDone: 'Samples purchased',
        sampleOrderDoneDescription:
          'You have successfully purchased your samples.',
        samplesPending: 'Samples production',
        samplesPendingDescription:
          'Once you have purchased your samples, suppliers will begin sample production.',
        samplesOngoing: 'Samples in production',
        samplesOngoingDescription: 'Your samples are in production.',
        samplesBlocked: 'Sample production pending',
        samplesBlockedDescription:
          'Resolve the blockers to continue sample production.',
        samplesDone: 'Samples completed',
        samplesDoneDescription: 'Your samples are complete.',
        orderPending: 'Order completion',
        orderPendingDescription:
          'Once you have approved a sample, you can place an order.',
        orderOngoing: 'Order in production',
        orderOngoingDescription: 'Your order is in production.',
        orderBlocked: 'Pending order',
        orderBlockedDescription:
          'Resolve the blockers to continue producing your order.',
        orderDone: 'Order completed',
        orderDoneDescription: 'Your order is complete.',
        additionalFiles: 'Additional Files',
        number_of_units_per_variant: {
          placeholder: 'Quantity',
          default: 'Total Units'
        },
        number_of_units_per_variant_bis: {
          placeholder: 'Quantity Per Variant',
          default: 'Total Units'
        },
        materials: {
          placeholder: 'Materials',
          default: 'Material'
        },
        colors: {
          placeholder: 'Colors',
          default: 'Color'
        },
        sizes: {
          placeholder: 'Sizes',
          default: 'Size'
        },
        technical_details: {
          placeholder: 'Technical and Design Details',
          default: 'Technical detail'
        },
        suppliers_location: {
          placeholder: 'Sourcing Location',
          default: 'Sourcing Location'
        },
        reference_sample: {
          placeholder: 'Reference Sample',
          default: 'Reference Sample'
        },
        sample_address: {
          placeholder: 'Sample Shipping Address',
          default: 'Sample Shipping Address'
        },
        extra_information: {
          default: 'Notes',
          placeholder: 'Notes'
        }
      }
    }
  }
});

const { t } = i18next;

/**
 * Account registration
 */

export interface registerLoginCopy {
  cta: string;
  disclaimer: string;
  errorEmailRequired: string;
  errorValidEmail: string;
  formSubtitle: string;
  formTitle: string;
  promoText1: string;
  promoText2: string;
  toOtherForm: string;
  joinWaitlist?: string;
  goToLogin?: string;
  validationDescription: string;
  validationTitle: string;
  validationWarning: string;
}

export const registerLoginCopies: {
  login: registerLoginCopy;
  register: registerLoginCopy;
  expired: registerLoginCopy;
} = {
  expired: {
    cta: t('expiredCta'),
    disclaimer: t('expiredDisclaimer'),
    errorEmailRequired: t('errorEmailRequired'),
    errorValidEmail: t('errorValidEmail'),
    formSubtitle: t('enterYourEmail'),
    formTitle: t('expiredFormTitle'),
    promoText1: t('expiredPromoText1'),
    promoText2: t('expiredPromoText2'),
    toOtherForm: t('expiredToOtherForm'),
    validationDescription: t('expiredValidationDescription'),
    validationTitle: t('expiredValidationTitle'),
    validationWarning: t('expiredValidationWarning')
  },
  login: {
    cta: t('loginCta'),
    disclaimer: t('loginDisclaimer'),
    errorEmailRequired: t('errorEmailRequired'),
    errorValidEmail: t('errorValidEmail'),
    formSubtitle: t('enterYourEmail'),
    formTitle: t('loginFormTitle'),
    promoText1: t('loginPromoText1'),
    promoText2: t('loginPromoText2'),
    toOtherForm: t('loginToOtherForm'),
    validationDescription: t('loginValidationDescription'),
    validationTitle: t('loginValidationTitle'),
    validationWarning: t('loginValidationWarning')
  },
  register: {
    cta: t('registerCta'),
    disclaimer: t('registerDisclaimer'),
    errorEmailRequired: t('errorEmailRequired'),
    errorValidEmail: t('errorValidEmail'),
    formSubtitle: t('enterYourEmail'),
    formTitle: t('registerFormTitle'),
    promoText1: t('registerPromoText1'),
    promoText2: t('registerPromoText2'),
    toOtherForm: t('registerToOtherForm'),
    joinWaitlist: t('joinWaitlist'),
    goToLogin: t('goToLogin'),
    validationDescription: t('registerValidationDescription'),
    validationTitle: t('registerValidationTitle'),
    validationWarning: t('registerValidationWarning')
  }
};

/**
 * Search suggestions
 */

export const randomSuggestions = {
  productSearches: [t('exampleSearch')]
};

/**
 * Product statuses
 */

const TESTING_REASON_CODE =
  'This is the text that will be displayed instead of the stage description, if it is present in the statusReasonCode';

export const statusCopies = {
  pending: {
    title: t('pending')
  },
  ongoing: {
    title: t('ongoing')
  },
  blocked: {
    title: t('blocked')
  },
  done: {
    title: t('completed')
  }
};

export const statusReasonsCopies: Record<string, string> = {
  TESTING_REASON_CODE
};

export const stageCopies = {
  rfq_received: {
    pending: {
      title: t('documentsPending'),
      description: t('documentsPendingDescription')
    },
    ongoing: {
      title: t('documentsOngoing'),
      description: t('documentsOngoingDescription')
    },
    blocked: {
      title: t('documentsBlocked'),
      description: t('documentsBlockedDescription')
    },
    done: {
      title: t('documentsDone'),
      description: t('documentsDoneDescription')
    }
  },
  rfq_sent: (productName: string = '', numOfSuppliers: number) => ({
    pending: {
      title: t('rfqPending'),
      description: t('rfqPendingDescription')
    },
    ongoing: {
      title: t('rfqOngoing'),
      description: t('rfqOngoingDescription')
    },
    blocked: {
      title: t('rfqBlocked'),
      description: t('rfqBlockedDescription')
    },
    done: {
      title: t('rfqDone'),
      description: (
        <span>
          Your Request for Quote has been sent to{' '}
          {numOfSuppliers ? <strong>{numOfSuppliers}</strong> : ''} suppliers.
        </span>
      )
    }
  }),
  quotes_received: (productName: string = 'product') => ({
    pending: {
      title: t('quotesPending'),
      description: t('quotesPendingDescription')
    },
    ongoing: {
      title: t('quotesOngoing'),
      description: t('quotesOngoingDescription')
    },
    blocked: {
      title: t('quotesBlocked'),
      description: t('quotesBlockedDescription')
    },
    done: {
      title: t('quotesDone'),
      description: (
        <Text>
          You have received quotes from suppliers. Refer to the{' '}
          <RouterLink
            to="/updates"
            style={{ color: 'var(--mantine-color-cavela-primary-6)' }}
          >
            Updates page
          </RouterLink>{' '}
          to view your quotes
        </Text>
      )
    }
  }),
  samples_ordered: {
    pending: {
      title: t('sampleOrderPending'),
      description: t('sampleOrderPendingDescription')
    },
    ongoing: {
      title: t('sampleOrderOngoing'),
      description: t('sampleOrderOngoingDescription')
    },
    blocked: {
      title: t('sampleOrderBlocked'),
      description: t('sampleOrderBlockedDescription')
    },
    done: {
      title: t('sampleOrderDone'),
      description: t('sampleOrderDoneDescription')
    }
  },
  samples_produced: {
    pending: {
      title: t('samplesPending'),
      description: t('samplesPendingDescription')
    },
    ongoing: {
      title: t('samplesOngoing'),
      description: t('samplesOngoingDescription')
    },
    blocked: {
      title: t('samplesBlocked'),
      description: t('samplesBlockedDescription')
    },
    done: {
      title: t('samplesDone'),
      description: t('samplesDoneDescription')
    }
  },
  order_produced: {
    pending: {
      title: t('orderPending'),
      description: t('orderPendingDescription')
    },
    ongoing: {
      title: t('orderOngoing'),
      description: t('orderOngoingDescription')
    },
    blocked: {
      title: t('orderBlocked'),
      description: t('orderBlockedDescription')
    },
    done: {
      title: t('orderDone'),
      description: t('orderDoneDescription')
    }
  }
};

/**
 * Menu items
 */

type MenuItems = {
  [key in RoutePath]?: string;
};

export const menuItems: MenuItems = {
  '/': '',
  products: t('products'),
  updates: t('updates'),
  profile: t('profile'),
  login: t('login'),
  register: t('register')
};

/**
 * View mixins
 * View mixins are structures of pure components
 * and reusable copy to simplify rendering
 */

export const appCopies = {
  questions: {
    title: t('questionsSectionTitle'),
    description: ''
  }
};

export const notAccessibleCopies = {
  text404: {
    title: t('cavela404'),
    description: <RouterLink to="/">{t('goHome')}</RouterLink>
  },
  notLoggedIn: {
    title: t('cavela403'),
    description: t('checkYourEmail')
  },
  expiredCredentials: {
    title: t('cavela401'),
    description: (
      <Text>
        {t('contactLink')}
        <a target="_blank" rel="noreferrer" href={`mailto:${SUPPORT_EMAIL}`}>
          {SUPPORT_EMAIL}
        </a>
      </Text>
    )
  },
  generic: {
    title: t('cavela500'),
    description: (
      <Text>
        {t('contactProblem')}
        <a target="_blank" rel="noreferrer" href={`mailto:${SUPPORT_EMAIL}`}>
          {SUPPORT_EMAIL}
        </a>
      </Text>
    )
  }
};

export const questionsCopies = {
  sectionTitle: t('questionsSectionTitle'),
  disclaimer: (
    <Text>
      Answers cannot be changed once submitted. If you need to change your
      answer, please{' '}
      <a target="_blank" rel="noreferrer" href={`mailto:${SUPPORT_EMAIL}`}>
        contact us
      </a>
      .
    </Text>
  )
};
