import { IconLink, IconReportOff } from '@tabler/icons-react';

import { Container, Flex, Title } from '@mantine/core';

import { getNotAccessibleCopies } from '../../utils/copiesGetters';

interface NotAccessibleProps {
  variant?: string;
  title?: string | null;
  iconVariant?: 'generic' | 'no-results';
  description?: string | JSX.Element;
}

function makeIconVariant(iconVariant: string) {
  switch (iconVariant) {
    case 'generic':
      return <IconLink size={100} />;
    case 'no-results':
      return <IconReportOff size={100} />;
    default:
      return <IconLink size={100} />;
  }
}

export default function NotAccessible({
  variant = 'generic',
  iconVariant = 'generic',
  title,
  description
}: NotAccessibleProps) {
  let errorTitle = title;
  let errorDescription = description;

  if (!title) {
    errorTitle = getNotAccessibleCopies(variant).title;
  }

  if (!description) {
    errorDescription = getNotAccessibleCopies(variant).description;
  }

  return (
    <Container>
      <Flex
        align="center"
        justify="center"
        gap="lg"
        style={{ height: '100vh' }}
        direction="column"
      >
        {makeIconVariant(iconVariant)}
        <Title ta="center" data-testid="error-title">
          {errorTitle}
        </Title>
        <Flex>{errorDescription}</Flex>
      </Flex>
    </Container>
  );
}
