import React from 'react';

import AuthContext from '../context/Auth';
import useUserInfo, { UserInfo } from '../hooks/useUserInfo';

export type AuthContextValue = {
  isLoggedIn: boolean;
  isLoading: boolean;
  userInfo: UserInfo | null;
  refetch: () => void;
  error: string | null;
};

export default function AuthProvider({ children }: { children: any }) {
  const { userInfo, isLoading, error, refetch } = useUserInfo();

  return (
    <AuthContext.Provider
      value={
        {
          isLoggedIn: userInfo !== null,
          isLoading,
          error,
          userInfo: userInfo,
          refetch
        } as AuthContextValue
      }
    >
      {children}
    </AuthContext.Provider>
  );
}
