import { useState } from 'react';

import i18next from 'i18next';

import { captureException } from '../services/reporting';
import { API_URL } from '../settings';
import { CustomError } from '../types/errorTypes';
import { fetchWithAuth } from '../utils/fetchWithAuth';

const { t } = i18next;

function makeQuestionsUrl(
  productId: string,
  stageId: string,
  questionId: number
) {
  return `${API_URL}/api/products/${productId}/${stageId}/questions/${questionId}`;
}

interface SendAnswerProps {
  productId: string;
  stageId: string;
  questionId: number;
  answer: string | string[];
}

interface sendMultipleAnswersProps {
  productId: string;
  stageId: string;
  answers: {
    [key: number]: string;
  };
}

export default function useQuestions() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>('');

  async function sendMultipleAnswers({
    productId,
    stageId,
    answers
  }: sendMultipleAnswersProps) {
    setLoading(true);
    setError('');
    const url = `${API_URL}/api/products/${productId}/${stageId}/questions/`;

    try {
      const response = await fetchWithAuth(url, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ ...answers })
      });

      if (response.status !== 200) {
        throw new CustomError({
          message: `Error fetching product detail`,
          statusCode: response.status
        });
      }

      await response.json();

      return;
    } catch (error: any) {
      const extra = {
        statusCode: error.statusCode
      };
      captureException({ error, extra });
      return setError(t('errorSendingAnswers'));
    } finally {
      setLoading(false);
    }
  }

  async function sendAnswer({
    productId,
    stageId,
    questionId,
    answer
  }: SendAnswerProps) {
    setLoading(true);
    const url = makeQuestionsUrl(productId, stageId, questionId);

    try {
      const response = await fetchWithAuth(url, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ answer })
      });

      if (response.status !== 200) {
        throw new CustomError({
          message: `Error fetching product detail`,
          statusCode: response.status
        });
      }

      const data = await response.json();
      return { error: null, data };
    } catch (error: any) {
      const extra = {
        statusCode: error.statusCode
      };
      captureException({ error, extra });
      return { error: 'Something went wrong. Please try again later.' };
    } finally {
      setLoading(false);
    }
  }

  return {
    loading,
    sendAnswer,
    sendMultipleAnswers,
    error
  };
}
