import { Stage, Status } from './types/ProductTypes';

/* SYSTEM ENVIRONMENT VARIABLES */
export const APP_URL = process.env.REACT_APP_URL;
export const API_URL = process.env.REACT_APP_BACKEND_URL;
export const ENVIRONMENT =
  process.env.REACT_APP_ENVIRONMENT || 'environment_not_set';
export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
export const DEBUG_MODE_ENABLED =
  process.env.REACT_APP_DEBUG_MODE_ENABLED || false;
export const ANALYTICS_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;

/* APP SETTINGS */
export const PRODUCT_FEATURES_BY_STAGE: { [key: string]: Stage[] } = {
  fileUploads: ['rfq_received'],
  fileDelete: ['rfq_received'],
  titleEdit: ['rfq_received']
};
export const ALLOW_EDITING_STAGE_STATUS: Status[] = ['blocked'];
export const MAX_FILE_UPLOAD_SIZE_MB = 25;
// Keep MAX_FILE_UPLOAD_SIZE_MB in sync at settings.py
export const CONTACT_US_URL = 'https://cavela.com/contact-us-join-the-waitlist';
export const CAVELA_WEB_URL = 'https://cavela.com';
export const SUPPORT_EMAIL = 'help@cavela.com';

// FEATURE FLAGS
// Also listed at backend/backend/feature_flags.py (keep both in sync)
// currently, none
