import { DEBUG_MODE_ENABLED } from '../settings';

export function info(...args: any) {
  if (DEBUG_MODE_ENABLED) {
    //eslint-disable-next-line no-console
    console.info(...args);
  }
}

export function warn(...args: any) {
  if (DEBUG_MODE_ENABLED) {
    //eslint-disable-next-line no-console
    console.warn(...args);
  }
}

export function failure(...args: any) {
  if (DEBUG_MODE_ENABLED) {
    //eslint-disable-next-line no-console
    console.error(...args);
  }
}
