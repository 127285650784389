import authService from '../services/authService';
import { failure } from '../services/logger';

const fetchWithAuth = async (url, options = {}) => {
  const authToken = authService.getAuthToken();
  if (authToken) {
    // eslint-disable-next-line no-warning-comments
    // TODO: dirty, as it fetches the token twice
    return fetchWithAuthToken(url, options);
  } else {
    return fetchWithJWTAuth(url, options);
  }
};

const fetchWithAuthToken = async (url, options = {}) => {
  const authToken = authService.getAuthToken();
  const headers = {
    ...options.headers
  };
  headers['Authorization'] = `Token ${authToken}`;
  const impersonation = authService.getImpersonation();
  if (impersonation && impersonation !== '0') {
    headers['Impersonation'] = impersonation;
  }
  return fetch(url.toString(), { ...options, headers });
};

const fetchWithJWTAuth = async (url, options = {}) => {
  // Retrieve the user object from local storage
  const userJson = localStorage.getItem('user');
  if (!userJson) {
    throw new Error('Not authenticated');
  }
  const user = userJson ? JSON.parse(userJson) : null;
  const token = user ? user.access : null;

  // Include the Authorization header with the JWT token if available
  const headers = {
    ...options.headers,
    Authorization: token ? `Bearer ${token}` : ''
  };

  try {
    let response = await fetch(url, { ...options, headers });

    // If the token is expired or invalid, try to refresh it
    if (response.status === 401) {
      try {
        const newToken = await authService.refreshAccessToken();
        headers['Authorization'] = `Bearer ${newToken}`;
        response = await fetch(url, { ...options, headers }); // Retry the request with the new token
      } catch (error) {
        failure('Failed to refresh token:', error);
        throw error;
      }
    }
    return response;
  } catch (error) {
    failure('Fetch error:', error.message);
    throw error;
  }
};

export { fetchWithAuth };
