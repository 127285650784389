export default function StepProductRfqQuestions({
  onNext,
  onPrev
}: {
  onNext: () => void;
  onPrev: () => void;
}) {
  return (
    <div className="step-product-rfq-questions">
      <label htmlFor="product-rfq-questions">Product RFQ Questions</label>
      <textarea id="product-rfq-questions" name="product-rfq-questions" />
    </div>
  );
}
