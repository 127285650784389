import { ReactNode } from 'react';

import { Flex, Title } from '@mantine/core';

export default function StageSectionWrapper({
  sectionTitle,
  children
}: {
  sectionTitle?: string | null;
  children: ReactNode;
}) {
  return (
    <Flex flex={1} direction="column" gap="lg">
      {sectionTitle && <Title size="h3"> {sectionTitle} </Title>}
      {children}
    </Flex>
  );
}
