import { Text } from '@cavela/ui';

interface Props {
  children: Element | string;
  subheading?: string;
}

const PageHeading = ({ children, subheading = '' }: Props) => (
  <div className="w-full">
    <Text heading style={{ color: 'black', fontSize: '2.5em' }}>
      {children}
    </Text>
    {subheading && (
      <>
        <div className="my-2">
          <Text color="#6E747D">{subheading}</Text>
        </div>
      </>
    )}
  </div>
);

export default PageHeading;
