import { Box, Flex } from '@mantine/core';

import { SUPPORT_EMAIL } from '../../settings';

export default function AuthFormConfirmation({
  action,
  userEmail
}: {
  action: string;
  userEmail: string;
}) {
  return (
    <Flex
      direction="column"
      align="flex-start"
      justify="center"
      w="100%"
      maw={400}
      data-testid="auth-form-confirmation"
      className="animate-fadeIn"
    >
      <img src="/icons/mail.svg" alt="mail" className="mb-4 size-16" />
      <h2
        className="font-title text-black"
        style={{ fontWeight: 900, fontSize: '1.5rem', lineHeight: '1.8rem' }}
      >
        Your sign-in link has been mailed to{' '}
        <span className="text-[#000EEF]">{userEmail}</span>
      </h2>

      <Box mt="md" className="text-black">
        Please check your inbox and use the link in the e-mail to access your
        account. If you don't see the e-mail, check your spam folder or{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href={`mailto:${SUPPORT_EMAIL}`}
          className="text-[#000EEF]"
        >
          contact us.
        </a>
      </Box>
    </Flex>
  );
}
