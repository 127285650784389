import { IconDownload, IconUpload } from '@tabler/icons-react';
import { useState } from 'react';

import i18next from 'i18next';

import { Button, FileButton, Flex, Text } from '@mantine/core';

import styles from './FileDragDrop.module.css';

const { t } = i18next;

interface FileDragDropProps {
  onDrop: (files: File[]) => void;
}

export default function FileDragDrop({ onDrop }: FileDragDropProps) {
  const [dragging, setDragging] = useState(false);

  function handleDrop(event: React.DragEvent<HTMLDivElement>) {
    event.preventDefault();
    onDrop(Array.from(event.dataTransfer.files));
    setDragging(false);
  }

  function handleDragEnter(event: React.DragEvent<HTMLDivElement>) {
    event.preventDefault();
    setDragging(true);
  }

  function handleDragLeave(event: React.DragEvent<HTMLDivElement>) {
    event.preventDefault();
    setDragging(false);
  }

  return (
    <Flex
      gap="md"
      p="lg"
      className={`${styles.uploadDrag} ${dragging ? styles.uploadDrop : ''}`}
      onDrop={handleDrop}
      onDragOver={(event) => event.preventDefault()}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      data-testid="dropzone"
    >
      {dragging ? (
        <>
          <IconDownload size="36" />
          <Text>{t('drop')}</Text>
        </>
      ) : (
        <>
          <IconUpload size={24} />
          <Flex direction="column" justify="center" align="center">
            <Text>{t('dragDrop')}</Text>
            <Text c="cavela-shadow.5">{t('maxSize')}</Text>
          </Flex>

          <FileButton
            aria-label="upload files"
            onChange={onDrop}
            multiple
            data-testid="choose-files-btn"
          >
            {(props) => (
              <Button
                variant="light"
                {...props}
                data-testid="choose-files-btn-click"
              >
                {t('chooseFiles')}
              </Button>
            )}
          </FileButton>
        </>
      )}
    </Flex>
  );
}
