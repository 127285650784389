import { useCallback, useEffect, useState } from 'react';

import authService from '../services/authService';
import { captureException, setUser } from '../services/reporting';
import { BACKEND_URL } from '../settings';
import { CustomError } from '../types/errorTypes';
import { fetchWithAuth } from '../utils/fetchWithAuth';

export interface UserInfo {
  id: string;
  email: string;
  username: string;
  featureFlags: string[];
  impersonating: ImpersonatingInfo | null;
  settings?: {
    [key: string]: any;
  };
  isStaff?: boolean;
  language: string;
  company_name?: string;
  addresses?: Address[];
  avatar?: string;
}

export interface Address {
  name: string;
  line_1: string;
  line_2: string;
  city: string;
  state: string;
  zip_code: string;
  country: string;
  contact_name: string;
  contact_phone: string;
  is_default?: number;
}

export interface ImpersonatingInfo {
  id: string;
  username: string;
}

function userMapper(data: any): UserInfo {
  return {
    id: data.id,
    email: data.email,
    username: data.username,
    featureFlags: data.featureflags,
    impersonating: data.impersonating,
    settings: data.settings || {},
    isStaff: data.is_staff || false,
    language: data?.language || 'en',
    company_name: data?.company_name || '',
    addresses: data?.addresses || [],
    avatar: data?.avatar || ''
  };
}

interface UseUserInfoReturn {
  userInfo: UserInfo | null;
  isLoading: boolean;
  error: string | null;
  refetch: () => void;
}

const USER_URL = `${BACKEND_URL}/api/me`;

const useUserInfo = (): UseUserInfoReturn => {
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const fetchUserInfo = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await fetchWithAuth(USER_URL);
      if (response.status === 401) {
        setError(`expiredCredentials`);
        await authService.logout();
        throw new CustomError({
          message: `expiredCredentials`,
          statusCode: response.status
        });
      } else if (!response.ok) {
        setError(`generic`);
        throw new CustomError({
          message: `There's been a problem with the authentication`,
          statusCode: response.status
        });
      }
      const data = await response.json();
      const mappedUser = userMapper(data);
      setUserInfo(mappedUser);

      setUser({
        id: data.id,
        email: data.email,
        username: data.username,
        impersonating: data.impersonating
      });
    } catch (error: any) {
      const extra = {
        statusCode: error.statusCode
      };
      captureException({ error, extra });
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchUserInfo();
  }, [fetchUserInfo]);

  const refetch = useCallback(() => {
    fetchUserInfo();
  }, [fetchUserInfo]);

  return { userInfo, isLoading, error, refetch };
};

export default useUserInfo;
