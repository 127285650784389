import { DangerousRounded } from '@mui/icons-material';

const ErrorNotification = ({ children }: { children: React.ReactNode }) => (
  <aside className="fixed left-2 right-2 bottom-2 top-auto flex items-center justify-start gap-1 p-1 bg-[var(--mantine-color-cavela-error-0)] text-[var(--mantine-color-cavela-error-5)] z-50 rounded-md">
    <DangerousRounded
      htmlColor="var(--mantine-color-cavela-error-5)"
      fontSize="small"
    />
    &nbsp;{children}
  </aside>
);

export default ErrorNotification;
