import React from 'react';

import { Image } from '@mui/icons-material';

import { SectionWrapper } from 'src/components';

import Badge from '../Badge/Badge';
import RfqDraftCard from '../RfqDraftCard/RfqDraftCard';
import useLogic from './logic';

const RfqDisplay = ({ isListView }: { isListView: boolean }) => {
  const { rfqs, redirectToRfq } = useLogic();

  return (
    <>
      {rfqs.length > 0 && !isListView && (
        <>
          <SectionWrapper testId="rfqs-in-draft-status">
            <h3 className="text-2xl font-extrabold text-black mb-4 grid gap-4 w-full max-w-6xl mx-auto">
              <strong>Drafts</strong>
            </h3>

            <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 w-full max-w-6xl mx-auto">
              {rfqs.map((rfq) => (
                <RfqDraftCard
                  key={rfq.id}
                  id={rfq.id}
                  image={rfq.image}
                  documentName={rfq.document.name}
                  questionsLength={rfq.questions.length}
                  createdAt={rfq.created_at}
                  redirectToRfq={redirectToRfq}
                />
              ))}
            </div>
          </SectionWrapper>
        </>
      )}

      {rfqs.length > 0 && isListView && (
        <SectionWrapper testId="rfqs-in-draft-status">
          <h3 className="text-2xl font-extrabold text-black mb-4 grid gap-4 w-full max-w-6xl mx-auto">
            <strong>Drafts</strong>
          </h3>
          <table className="flex flex-col bg-white w-full min-w-[60rem] max-w-6xl mx-auto rounded-xl shadow-md">
            <thead>
              <tr
                className="flex gap-4 items-start justify-start text-left min-w-full p-4"
                style={{
                  borderBottom: '1px solid var(--mantine-color-cavela-shadow-2)'
                }}
              >
                <th className="min-w-80 uppercase text-xs text-[var(--mantine-color-cavela-shadow-7)]">
                  Quote request name
                </th>
                <th className="w-24 max-w-[25%] uppercase text-xs text-[var(--mantine-color-cavela-shadow-7)]">
                  Image
                </th>
                <th className="min-w-[15%] uppercase text-xs text-[var(--mantine-color-cavela-shadow-7)]">
                  Progress
                </th>
                <th className="w-40 max-w-[30%] uppercase text-xs text-[var(--mantine-color-cavela-shadow-7)] text-center">
                  Created Date
                </th>
                <th className="min-w-40 max-w-[25%] uppercase text-xs text-[var(--mantine-color-cavela-shadow-7)] ml-auto">
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {rfqs.map((rfq) => (
                <tr className="flex gap-4 items-center justify-start bg-white min-w-full p-4 overflow-x-auto">
                  <td className="flex items-center min-w-80 max-w-80">
                    <button
                      onClick={() => redirectToRfq(rfq.id)}
                      className="truncate"
                    >
                      <span>
                        <strong>{rfq.document.name}</strong>
                      </span>
                    </button>
                  </td>
                  <td className="flex items-center min-w-24 max-w-24">
                    <div className="relative flex items-center size-14 rounded-lg bg-[#D3D5D9] justify-center w-[68px] h-[52px]">
                      {rfq.image ? (
                        <img
                          src={rfq.image}
                          alt="rfq"
                          className="object-cover rounded-xl"
                        />
                      ) : (
                        <Image
                          htmlColor="#6E747D"
                          fontSize="large"
                          style={{ width: '60%', height: '60%' }}
                        />
                      )}
                    </div>
                  </td>
                  <td className="flex items-center min-w-[15%] max-w-[15%]">
                    <span>
                      <strong>{rfq.questions.length} answers</strong>
                    </span>
                  </td>
                  <td className="flex items-center justify-center text-center w-40 max-w-[30%]">
                    {new Date(rfq.created_at).toLocaleDateString('es-ES', {
                      month: 'short',
                      day: 'numeric',
                      year: 'numeric'
                    })}
                  </td>
                  <td className="flex items-center min-w-40 max-w-[25%] ml-auto">
                    <Badge variant="draft" text="Draft" />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </SectionWrapper>
      )}
    </>
  );
};

export default RfqDisplay;
