import { useState } from 'react';

import i18next from 'i18next';

import { GENERIC_OPTION } from '../components/ContactForm/ContactForm';

import { captureException } from '../services/reporting';
import { CustomError } from '../types/errorTypes';

const { t } = i18next;

const SLACK_WEBHOOK_URL = process.env.REACT_APP_SLACK_WEBHOOK_URL;
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

interface DataFromContactForm {
  email: string;
  product: string;
  username: string;
  message: string;
  productId?: string;
}

interface SlackMessage {
  text: string;
  blocks: any[];
}

function formatSlackMessage(contactData: DataFromContactForm): SlackMessage {
  const subject =
    contactData.product === GENERIC_OPTION
      ? contactData.product
      : `Product: [${contactData.product}](${BACKEND_URL}/admin/cavela/product/${contactData.productId})`;

  const username = contactData.username
    ? contactData.username
    : 'Not logged in';
  return {
    text: ':email: New contact form submission',
    blocks: [
      {
        type: 'section',
        fields: [
          {
            type: 'mrkdwn',
            text: `:bear: *Username:*\n${username}`
          },
          {
            type: 'mrkdwn',
            text: `:email: *Email:*\n${contactData.email}`
          }
        ]
      },
      {
        type: 'divider'
      },
      {
        type: 'section',
        text: {
          type: 'mrkdwn',
          text: `:package: *Subject/Product:*\n${subject} `
        }
      },
      {
        type: 'section',
        text: {
          type: 'mrkdwn',
          text: `:left_speech_bubble: *Message:*\n${contactData.message}`
        }
      }
    ]
  };
}

export default function useSendContact() {
  const [loading, setLoading] = useState(false);

  async function sendContact(
    contactData: DataFromContactForm
  ): Promise<{ error: string | null }> {
    try {
      setLoading(true);
      if (SLACK_WEBHOOK_URL === undefined) {
        throw new Error('SLACK_WEBHOOK_URL is not defined');
      }

      const response = await fetch(SLACK_WEBHOOK_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: JSON.stringify(formatSlackMessage(contactData))
      });

      if (!response.ok) {
        throw new CustomError({
          message: 'Error sending contact form',
          statusCode: response.status
        });
      }

      return { error: null };
    } catch (error: any) {
      const extra = {
        statusCode: error.statusCode
      };
      captureException({ error, extra });
      return { error: t('contactFormError') };
    } finally {
      setLoading(false);
    }
  }
  return { sendContact, loading };
}
