import { useEffect, useState } from 'react';

import { captureException } from '../services/reporting';
import { API_URL } from '../settings';
import { CustomError } from '../types/errorTypes';
import { MappedProductDetail } from '../types/ProductTypes';
import { fetchWithAuth } from '../utils/fetchWithAuth';
import { productListMapper } from '../utils/productMappers';

const useProductsList = () => {
  const [productsList, setProductsList] = useState<MappedProductDetail[]>([]);
  const [loading, setLoading] = useState(true);

  const fetchProductsList = async () => {
    setLoading(true);

    try {
      const response = await fetchWithAuth(`${API_URL}/api/products/`);

      if (response.status !== 200) {
        throw new CustomError({
          message: `Error fetching product detail`,
          statusCode: response.status
        });
      }

      const data = await response.json();

      const mappedProductList = productListMapper(data);

      const sortedProducts = mappedProductList.sort((a, b) =>
        new Date(a.createdAt) < new Date(b.createdAt) ? 1 : -1
      );

      setProductsList(sortedProducts);
    } catch (error: any) {
      const extra = {
        statusCode: error.statusCode
      };

      captureException({ error, extra });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProductsList();
  }, []);

  return {
    fetchProductsList,
    productsList,
    loading
  };
};

export default useProductsList;
