import { ReactNode } from 'react';

import { Modal as MantineModal, Text } from '@mantine/core';

const { Root, Overlay, Content, Body } = MantineModal;

const Modal = (props: {
  title?: string | ReactNode;
  opened: boolean;
  centered?: boolean;
  fullScreen?: boolean;
  size?: string;
  onClose: () => void;
  children: ReactNode;
}) => {
  const {
    title,
    opened,
    centered,
    fullScreen,

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    size = 'md',
    onClose,
    children,

    ...additionalProps
  } = props;

  return (
    <Root
      opened={opened}
      centered={Boolean(centered)}
      fullScreen={Boolean(fullScreen)}
      onClose={onClose}
      {...additionalProps}
    >
      <Overlay />
      <Content bg="white" py="sm" radius="lg">
        {title && <Text>{title}</Text>}
        <Body>{children}</Body>
      </Content>
    </Root>
  );
};

export default Modal;
