import { useState } from 'react';

import { Carousel, Embla, useAnimationOffsetEffect } from '@mantine/carousel';

import { Box, Flex, Image, Modal } from '@mantine/core';

import styles from './ImageGallery.module.css';

export default function ImageGallery({
  markdownImages,
  dimmed = false
}: {
  dimmed?: boolean;
  markdownImages: string[];
}) {
  const TRANSITION_DURATION = 300;
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);

  const [embla, setEmbla] = useState<Embla | null>(null);

  useAnimationOffsetEffect(embla, TRANSITION_DURATION);
  return (
    <Box className={styles.grid}>
      {markdownImages.map((markDownImage, index) => (
        <Flex className={styles.thumbnailWrapper} key={index}>
          <img
            className={styles.thumbnail}
            onClick={() => {
              setSelectedImage(index);
              setShowModal(!showModal);
            }}
            src={markDownImage}
            alt="Question"
          />
        </Flex>
      ))}
      <Modal.Root
        opened={showModal}
        fullScreen
        onClose={() => setShowModal(false)}
      >
        <Modal.Overlay />
        <Modal.Content>
          <Modal.Header
            bg="#000000"
            styles={{
              header: {
                border: 'none'
              }
            }}
          >
            <Modal.CloseButton className={styles.closeButton} />
          </Modal.Header>
          <Modal.Body p={0}>
            {/* remove 60px from the header */}
            <Flex style={{ height: 'calc(100vh - 60px)' }}>
              <Carousel
                classNames={styles}
                slideSize="100%"
                withIndicators
                height="100%"
                style={{ flex: 1 }}
                slideGap="xl"
                getEmblaApi={setEmbla}
                onLoad={() => {
                  embla?.scrollTo(selectedImage);
                }}
              >
                {markdownImages.map((markDownImage, index) => (
                  <Carousel.Slide key={index}>
                    <Image
                      src={markDownImage}
                      alt="Question"
                      height="90%"
                      style={{ objectFit: 'contain', maxHeight: '100%' }}
                    />
                  </Carousel.Slide>
                ))}
              </Carousel>
            </Flex>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
    </Box>
  );
}
