import { ComponentProps } from 'react';
import ReactMarkdown from 'react-markdown';

import { mdVariableSubstitution } from '../../utils/mdVariableSubstitution';

interface MarkdownProps extends ComponentProps<typeof ReactMarkdown> {
  replace?: { [key: string]: string };
  children?: any;
}

/**
 * This is a wrapper component that extends the ReactMarkdown component to allow for variable substitution in the markdown string.
 *
 * @param replace - An optional object where each key-value pair represents a variable and its replacement. I.e. In markding "{{producName}}" should be indicated as { productName: "Product Name"}
 * @param children - The markdown string to process. It can contain variables.
 * @param ...props - The props to pass to the react-markdown component as options.
 * @returns The processed markdown string with the variables replaced.
 */

export default function CustomMarkdown({
  replace,
  children,
  ...props
}: MarkdownProps) {
  if (!children) {
    return null;
  }
  const mdReplaced = mdVariableSubstitution(children, replace);

  return <ReactMarkdown {...props}>{mdReplaced}</ReactMarkdown>;
}
