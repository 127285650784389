import { useState } from 'react';

import i18next from 'i18next';

import { captureException } from '../services/reporting';
import { API_URL } from '../settings';
import { CustomError } from '../types/errorTypes';
import { fetchWithAuth } from '../utils/fetchWithAuth';

const { t } = i18next;

interface Upload {
  files: File[];
  captions?: string[];
  productId: string;
  stageId: string;
  questionId?: number;
}

interface Delete {
  fileId: string;
  productId: string;
  stageId: string;
  questionId?: number;
}

const useFiles = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [files, setFiles] = useState<Upload>();

  const deleteFile = async ({ fileId, productId, stageId }: Delete) => {
    const url = `${API_URL}/api/products/${productId}/${stageId}/files/${fileId}`;

    setLoading(true);

    const payload = {
      file_id: fileId,
      product_id: productId,
      stage: stageId
    };

    try {
      const response = await fetchWithAuth(url, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      });

      if (response?.ok) {
        const data = await response.json();

        return { error: null, data };
      }
    } catch (error: any) {
      const extra = {
        statusCode: error.statusCode
      };

      captureException({ error, extra });

      return {
        error: t('errorUploadGeneric')
      };
    } finally {
      setLoading(false);
    }
  };

  const uploadFiles = async ({
    files,
    captions = [],
    productId,
    stageId,
    questionId
  }: Upload) => {
    setFiles({
      files,
      captions,
      productId,
      stageId,
      questionId
    });

    let url = `${API_URL}/api/products/${productId}/${stageId}/files/`;

    setLoading(true);
    setError(null);

    const body = new FormData();

    if (questionId) {
      url = `${API_URL}/api/products/${productId}/${stageId}/questions/${questionId}/files/`;

      body.set('question_id', `${questionId}`);
    }

    for (const file of files) {
      body.append('files', file);
    }

    for (const caption of [...captions]) {
      body.append('captions', caption);
    }

    try {
      const response = await fetchWithAuth(url, {
        method: 'POST',
        body
      });

      if (!response?.ok) {
        throw new CustomError({
          message: t('addFileError'),
          statusCode: response.status
        });
      }

      return { error: null };
    } catch (error: any) {
      const extra = {
        statusCode: error.statusCode
      };

      captureException({ error, extra });
      setError(t('errorUploadGeneric'));

      return { error: t('errorUploadGeneric') };
    } finally {
      setLoading(false);
    }
  };

  return {
    uploadFiles,
    files,
    setFiles,
    deleteFile,
    loading,
    error
  };
};

export default useFiles;
