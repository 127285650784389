import React from 'react';

import useLogic from './logic';
import { BadgeProps } from './props';

const Badge = ({ variant, text }: BadgeProps) => {
  const { bgColor } = useLogic({ variant, text });

  return (
    <span
      className={`${bgColor} text-sm pl-2 pr-4 py-2 inline-flex items-center rounded`}
    >
      {variant === 'draft' && (
        <img src="/icons/edit.svg" alt="edit" className="mr-2" />
      )}

      {text}
    </span>
  );
};

export default Badge;
