import { IconEdit } from '@tabler/icons-react';
import { useState } from 'react';

import { Modal } from '..';
import { t } from 'i18next';

import { ActionIcon, Flex, Modal as MantineModal, Title } from '@mantine/core';

import { ProductTitleForm } from './ProductTitleForm';

const { Header } = MantineModal;

function ProductTitleDisplay({
  title,
  setIsEditing,
  editable = false
}: {
  title: string;
  editable: boolean;
  setIsEditing: (isEditing: boolean) => void;
}) {
  return (
    <Flex gap="md">
      <Title
        order={1}
        data-testid="product-title-text"
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }}
      >
        {title}
      </Title>
      {editable && (
        <ActionIcon
          size="xl"
          variant="light"
          aria-label="Edit product title"
          data-testid="edit-product-title"
          onClick={() => setIsEditing(true)}
        >
          <IconEdit />
        </ActionIcon>
      )}
    </Flex>
  );
}

export default function ProductTitle({
  title: initialTitle,
  editable = false
}: {
  title: string;
  editable: boolean;
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [title, setTitle] = useState(initialTitle);

  return (
    <Flex>
      <ProductTitleDisplay
        title={title}
        setIsEditing={() => setIsEditing(true)}
        editable={editable}
      />

      <Modal
        opened={isEditing}
        onClose={() => setIsEditing(false)}
        title={<Header>{t('editProductTitle')}</Header>}
        centered
      >
        <ProductTitleForm
          title={title}
          setTitle={setTitle}
          onCancel={() => setIsEditing(false)}
        />
      </Modal>
    </Flex>
  );
}
