import { useCallback, useContext, useEffect, useState } from 'react';

import AuthContext from '../context/Auth';

export default function useFeatureFlag(featureName: string) {
  const { userInfo, isLoading: userLoading } = useContext(AuthContext);
  const [isFeatureEnabled, setIsFeatureEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Start as true, assume loading initially

  const checkIfUserEnabled = useCallback(async () => {
    // Return a promise that resolves to a boolean
    return new Promise<boolean>((resolve) => {
      if (!userLoading) {
        // Ensure auth check is completed
        if (userInfo?.featureFlags?.includes(featureName)) {
          resolve(true); // Feature is enabled for the user
        } else {
          resolve(false); // Feature is not enabled for the user
        }
      }
    });
  }, [featureName, userInfo, userLoading]);

  useEffect(() => {
    setIsLoading(true); // Ensure loading is true when starting the check
    checkIfUserEnabled().then((isEnabled) => {
      setIsFeatureEnabled(isEnabled); // Set based on the resolved promise value
      setIsLoading(false); // Loading is complete after setting the feature flag state
    });
  }, [checkIfUserEnabled]);

  return { isFeatureEnabled, isLoading };
}
