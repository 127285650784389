import { createContext } from 'react';

import { MappedProductDetail } from '../types/ProductTypes';

export interface ProductContextType {
  productDetail: MappedProductDetail | undefined; // Assuming productDetail could be undefined initially
  loading: boolean;
  currentStage: number;
  lastActiveStageIndex: number;
  error: string | null; // Assuming error could be string or null
  handleStageSelection: (stageId?: string) => void; // Function to handle stage selection
  refetch: (options?: { refreshStage: boolean }) => void; // Function to refetch the product detail
  editProduct: ({
    productId,
    data
  }: {
    productId: string;
    data: any;
  }) => Promise<{ error: string | null }>; // Function to edit product details
}

const ProductContext = createContext<ProductContextType>({
  productDetail: undefined,
  loading: true,
  error: null,
  currentStage: 0,
  lastActiveStageIndex: 0,
  handleStageSelection: () => {},
  refetch: () => {},
  editProduct: async () => ({ error: null })
});

export default ProductContext;
