export const QUESTION_ANSWERED = 'question_answered';
export const ONBOARDING_PRODUCT_NAME_STARTED =
  'onboarding_product_name_started';
export const ONBOARDING_PRODUCT_NAME_COMPLETED =
  'onboarding_product_name_completed';
export const ONBOARDING_PRODUCT_QUESTIONS_STARTED =
  'onboarding_product_questions_started';
export const ONBOARDING_PRODUCT_QUESTIONS_COMPLETED =
  'onboarding_product_questions_completed';
export const ONBOARDING_PRODUCT_FILES_STARTED =
  'onboarding_product_files_started';
export const ONBOARDING_PRODUCT_FILES_COMPLETED =
  'onboarding_product_files_completed';
export const ONBOARDING_PRODUCT_FINAL_STEP_STARTED =
  'onboarding_product_final_step_started';
export const ONBOARDING_PRODUCT_FINAL_STEP_COMPLETED =
  'onboarding_product_final_step_completed';
export const ONBOARDING_PRODUCT_CANCELLED = 'onboarding_product_cancelled';
